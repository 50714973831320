import Modal from "react-modal";
import ModalHeader from "../../modal-components/modal-header";
import Tag from "../../tag";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import ModalList from "../../modal-components/modal-list";
import Button from "../../button";
import ModalTextArea from "../../modal-components/modal-text-area";
import UserInputHeader from "../../user-input-header";
import './index.scss';
import PlaceholderImage from '../../../img/placeholder-image.svg';
import CloseIcon from '../../../img/x.svg';
import FileIcon from '../../../img/icons/fileMedium.svg'
import SuccessIcon from '../../../img/greenTickFilled.svg'
import { interactionReasons } from "../../pipeline/pipelineData";

const CheckOut = ({shopName, setClosed, setLoading, ...props}) => {
  const ref = useRef<HTMLInputElement>(null);

  const [selectedReason, setSelectedReason] = useState(null);
  const [prospectWonLost, setProspectWonLost] = useState(null);
  const [description, setDescription] = useState('');
  const [validationErrors, setValidationErrors] = useState<Array<string>>([]);
  const [imageUploaded, setImageUploaded] = useState<any>(null);
  const [decodedImage, setDecodedImage] = useState<any>(null);

  const isMobile = window.innerWidth < 768;

  const handleCloseModal = (forceReload=false) => {
    setClosed(forceReload,
      {
        description: description,
        prospectWonLost: prospectWonLost,
        decodedImage: decodedImage,
        selectedReason: selectedReason,
      }
    );
    setSelectedReason(null);
    setImageUploaded(null);
    setProspectWonLost(null);
    setDescription('');
    setValidationErrors([]);
  }

  const handleSubmit = () => {
    setValidationErrors([]);
    var tempErrors = [] as string[];

    if (!selectedReason) {
      tempErrors.push('reason');
    }

    if (!description) {
      tempErrors.push('description');
    }

    if (!imageUploaded) {
      tempErrors.push('image');
    }

    if (tempErrors.length) {
      setValidationErrors(tempErrors);
    } else {
      handleCloseModal(true);
    }
  }

  const headerTags = () => (
    <>
      <Tag text={shopName} size="large" type="primary" />
      {!isMobile && <Tag text={moment().format('DD/MM/YY HH:mm')} size="large" type="date" />}
    </>
  )

  useEffect(() => {
    if (imageUploaded) {
      const reader = new FileReader()
      reader.readAsDataURL(imageUploaded[0])
      reader.onload = () => {
        setDecodedImage(reader.result)
      }
    }
  }, [imageUploaded]);

  const formatBytes = (a,b=0) => {
    if (!+a) return "0 Bytes";
    else if (a < 1024) return "<1 KB";
    const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return`${parseFloat((a/Math.pow(1024,d)).toFixed(c))} ${["Bytes","KB","MB","GB","TB","PiB","EiB","ZiB","YiB"][d]}`
  }

  const checkOutButton = () => (
    <div className="modal__submit-wrapper">
      <Button
        buttonText="Confirm Check Out"
        disabled={!!validationErrors.length && (!description || !imageUploaded || !selectedReason)}
        type="success"
        onClick={() => handleSubmit()}
        grow />
    </div>
  )

  const closeProspectOptions = [
    {
      name: 'Won',
      value: true
    },
    {
      name: 'Lost',
      value: false
    }
  ]

  return (
    <Modal {...props} className='modal modal--auto-height' overlayClassName="overlay">
      <ModalHeader title="Check Out" setClosed={() => handleCloseModal()} tags={headerTags} />

      <div className="modal__content modal-scrollbar">
        <UserInputHeader
          title="Photo of Branded Takeaway"
          description="What does the shop front look like?"
          modal
          required
          error={validationErrors.includes('image')}
          filled={!!imageUploaded}
          nonSticky />

        <div className="image-upload">
          <div className="image-upload__cta" onClick={() => ref?.current?.click()}>
            <img src={PlaceholderImage} alt="Placeholder image" />
            <span className="image-upload__cta-heading">Click here to upload a photo</span>
            <span className="image-upload__cta-desc">You will be taken to your camera</span>
          </div>

          {imageUploaded && 
            <div className="image-upload__file">
              <div className="image-upload__icon-wrapper">
                <img src={FileIcon} alt="File icon" className="image-upload__file-icon" />
                <img src={SuccessIcon} alt="File upload success" className="image-upload__success-icon" />
              </div>

              <div className="image-upload__file-text">
                <span className="image-upload__file-name">{imageUploaded[0].name}</span>
                <span className="image-upload__file-size">{formatBytes(imageUploaded[0]?.size)}</span>
              </div>

              <div onClick={e => {e.stopPropagation(); setImageUploaded(null)}} className="image-upload__close-wrapper">
                <img src={CloseIcon} alt="Close icon" className="image-upload__close-icon" />
              </div>
            </div>
          }
        </div>

        <input ref={ref} type="file" id="selfie" name="selfie" accept="image/*" capture="environment" onChange={e => setImageUploaded(e.target.files)} className="image-upload__input" />

        <ModalList
          title="Reason for Interaction"
          description="Why have you gone to this shop?"
          required
          options={interactionReasons}
          selectedOption={selectedReason}
          onClick={setSelectedReason}
          error={validationErrors.includes('reason')} />

        <ModalTextArea
          value={description}
          title="Notes"
          onChange={ev => setDescription(ev.target.value)}
          required
          error={validationErrors.includes('description')}
          filled={!!description} />

        <ModalList
          title="Close Assigned Prospect?"
          description="Log the final outcome of this sale."
          options={closeProspectOptions}
          selectedOption={prospectWonLost}
          onClick={setProspectWonLost} />

        {!isMobile && checkOutButton()}
      </div>

      {isMobile && checkOutButton()}
    </Modal>
  )
}

export default CheckOut;
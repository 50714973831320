import CheckInTimerTag from "../overview/checkin-timer-tag";
import LongLogo from '../../../img/logoLong.svg'
import UserTag from "../user-tag";
import './index.scss';

interface Props {
  checkinData?: any,
  user?: string | null,
  onClick?: () => void
}

const ProspectRibbon = ({checkinData = null, user = null, onClick}: Props) => (
  <div onClick={onClick} className={`prospect-ribbon ${user ? 'prospect-ribbon--overview' : ''} ${checkinData?.name ? 'prospect-ribbon--checked-in' : ''}`}>
    <UserTag user={user} ribbon />

    {checkinData?.name
      ? <CheckInTimerTag checkinData={checkinData} />
      : <img src={LongLogo} />
    }
  </div>
)

export default ProspectRibbon;
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import {Admin} from '../../../fmo-api-sdk';
import PuffLoader from "react-spinners/PuffLoader";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Marketing from './marketing'
import Info from './info'
import NewTab from "../../../img/icons/newTab.png";
import { setBreakdownId } from "../../../app/slices/breakdownSlice";
import { getUserId } from "../../../app/auth";

const Breakdown = ({shop, shopData}: {shop?: Admin.ShopOverview, shopData?: Admin.AdminShop}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [overviewData, setShopOverview] = useState<Admin.ShopOverview>();
  const [platformData, setPlatformData] = useState({});
  const [orderData, setOrderData] = useState<Admin.ShopTransaction[] | Admin.ShopJob[] | Admin.ShopOrder[]>([]);
  const [performanceData, setPerformanceData] = useState<any>([]);
  const [menuHits, setMenuhits] = useState<string | number>('-');
  const breakdownId = useSelector((state: any) => state.breakdownId);
  const location = useLocation();
  const {state = {}} = location;
  const {agent = {}} = state as any;
  const token = useSelector((state: any) => state.auth.token);
  const userId = getUserId(token)

  const isPopout = location.pathname === '/breakdown' ? true : false;
  const usedId = shop?.wholesaler_id || breakdownId;

  useEffect(() => {
    (async () => {
      if (shop) setShopOverview(shop);
      else {
        await Admin.getShopOverview(usedId)
            .then(data => { if (data) setShopOverview(data) })
            .catch(error => { console.log(`Error fetching breakdown data: ${error}`) });
      }

      await Admin.getShopPlatform(usedId)
          .then(data => { setPlatformData(data.platforms) })
          .catch(error => { console.log(`Error fetching breakdown data: ${error}`) });

      await Admin.getShopHistory(usedId, "orders")
          .then(data => { setOrderData(data) })
          .catch(error => { console.log(`Error fetching breakdown data: ${error}`) });

      const agentToGet = agent.agent_id || userId;

      await Admin.getShopPerformance(agentToGet)
          .then(data => { setPerformanceData(data) });

      fetch(`https://api.feedmeonline.co.uk/takeaway/menuhits/${usedId}`)
        .then((res) => res.json())
        .then((data) => {
          setMenuhits(data.menu_hits);
        });

      setLoading(false)

      if (isPopout) {
        setTimeout(() => {
          window.print();
        }, 1000);
      };
    })();
  }, [usedId])

  function getSavings() {
    let competitorPackage = 17;
    let directDebit = false;
    let directDebitCost = 72;
    let shopPackage = 0;
  
    if (overviewData?.three_months_free === "No") {
      if (overviewData?.wholesaler_fee === "0.000") {
        activePackage = 'Direct Debit'
        directDebit = true;
      } else {
        activePackage = '8% Package'
        shopPackage = 8;
      }
    } 

    let unroundedSavings = unroundedRevenue * ((competitorPackage - shopPackage) / 100);
    if (directDebit) unroundedSavings = unroundedSavings - directDebitCost;

    let savings = (Math.round(unroundedSavings * 100) / 100).toFixed(2);
    return savings;
  }

  function getAvgValue() {
    let tempAvg;
    let avgValue
    if (!filteredOrderCount) tempAvg = 0;
    else tempAvg = (unroundedRevenue / filteredOrderCount)

    avgValue = (Math.round(tempAvg * 100) / 100).toFixed(2);
    return avgValue;
  }

  function getTrendPerc(): any {
    let tempPerc = Math.round(((shopPerformance?.last_month.orders/shopPerformance?.prev_month.orders) - 1) * 100);
    if (isNaN(tempPerc) || !isFinite(tempPerc)) return '-';
    return tempPerc;
  }

  let activePackage = 'Free Trial';

  const rejectedStatus = ["declined", "waiting payment"]
  const filteredOrderData = (orderData as Admin.ShopOrder[]).filter(order => !rejectedStatus.includes(order.order_status) && moment(order.order_created).format('M') === moment().subtract(1, 'M').format('M'));
  const filteredOrderCount = filteredOrderData.length;
  const unroundedRevenue = filteredOrderData.reduce((acc, order) => acc + parseFloat(order.order_total_grand), 0);
  const roundedRevenue = (Math.round(unroundedRevenue * 100) / 100).toFixed(2);
  const totalSavings = getSavings()
  const avgValue = getAvgValue();
  const shopPerformance = performanceData.filter(shop => shop.wholesaler_id === overviewData?.wholesaler_id)[0];
  const trend = shopPerformance?.last_month.trend;
  const trendPerc = getTrendPerc();

  const orderBlocks: Array<{}> = [
    {
      title: 'Total Orders',
      value: filteredOrderCount
    },
    {
      title: 'Total Revenue',
      value: `£${roundedRevenue}`
    },
    {
      title: 'Total Savings',
      value: `£${totalSavings}`
    },
    {
      title: 'Average Order Value',
      value: `£${avgValue}`
    },
    {
      title: 'Trend',
      value: trend,
      secondaryValue: trendPerc
    }
  ];

  function getBoxClass(title) {
    if (title !== "Trend" || !Number.isInteger(trendPerc) || trendPerc === 0) return 'breakdown__data-box';
    if (trend <= 0) return 'breakdown__data-box breakdown__data-box--downtrend';
    return 'breakdown__data-box breakdown__data-box--uptrend';
  };

  function popout() {
    alert(overviewData?.wholesaler_id);
    if (overviewData !== undefined) {
      dispatch(setBreakdownId(overviewData?.wholesaler_id));
    }
    window.open("/breakdown", "_blank");
  }

  return (
    <>
      {!loading ?
        <section className="breakdown">
          <div className="breakdown__section">
            <div className="breakdown__header">
              <div>
                <div className="breakdown__title-wrapper">
                  <h1 className="breakdown__title">{overviewData?.wholesaler_name}</h1>
                  <div className="breakdown__id-wrapper">
                    <div className="breakdown__id">{overviewData?.wholesaler_id}</div>
                    {!isPopout && <img src={NewTab} alt="New Tab" className="breakdown__popout-icon" onClick={() => popout()} />}
                  </div>
                </div>
                <div className="breakdown__subtitle">{moment().subtract(1, 'M').format('MMMM')} Breakdown</div>
              </div>
              <img src={`https://images.feedmeonline.co.uk/logos/${overviewData?.wholesaler_id}.png`} alt="Store Logo" className="breakdown__logo" />
            </div>
            <Info overviewData={overviewData} activePackage={activePackage} />
          </div>
          <div className="breakdown__section">
            <h2 className="breakdown__heading">Orders</h2>
            <div className="breakdown__box-wrapper">
              {orderBlocks.map((item: any, index) => {
                return (
                  <div key={index} className={`${getBoxClass(item.title)}`}>
                    <div>{item.title}</div>
                    <div className="breakdown__data-value">
                      {item.value}
                      {item.secondaryValue && <span className="breakdown__data-value--secondary">({item.secondaryValue}%)</span>}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="breakdown__section">
            <h2 className="breakdown__heading">Platforms Used</h2>
            <div className="breakdown__box-wrapper breakdown__box-wrapper--platforms">
              {Object.entries(platformData).map(([key, value]: [string, any]) => {
                return (
                  <div key={key} className="breakdown__data-box">
                    <div>{key}</div>
                    <div className="breakdown__data-value">{value}%</div>
                  </div>
                )
              })}
            </div>
          </div>
          <Marketing filteredOrderData={filteredOrderData} setLoading={setLoading} shopId={usedId} />
          {menuHits &&
            <div className="breakdown__section">
              <h2 className="breakdown__heading">Traffic</h2>
                <div className="breakdown__box-wrapper">
                  <div className="breakdown__data-box">
                    <div>Hits to Menu</div>
                    <div className="breakdown__data-value">{menuHits}</div>
                  </div>
                </div>
            </div>
          }
        </section>
        :
        <div className="spinner-border">
          <PuffLoader
              size={75}
              color={"#D82B31"}
              loading={true}
          />
        </div>
      }
    </>
  )
}

export default Breakdown;
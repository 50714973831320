import { useHistory } from "react-router-dom";
import logo from "../../img/logo.svg";
import './index.scss'
import Button from "../button";
import ArrowLeft from '../../img/arrowLeft.svg'

interface Props {
  headerTitle: string,
  back?: boolean,
  leftHeaderSlot?: any,
  rightHeaderSlot?: any
}
// review this ^^

const NavBarNew = ({headerTitle, back=false, leftHeaderSlot, rightHeaderSlot}: Props) => {
  const history = useHistory();

  return (
    <div className="nav-bar-new">
      <img src={logo} alt="FMO logo" onClick={() => history.push('/')} className="nav-bar-new__logo" />

      <div className="nav-bar-new__section">
        {back && (
          <Button
            buttonText="Go Back"
            onClick={() => history.goBack()}
            type="quaternary"
            icon={ArrowLeft}
            iconAltText="Go back button"
            condensed />
        )}

        {leftHeaderSlot}

        <div className={`nav-bar-new__title ${headerTitle === 'Shop Name' && 'nav-bar-new__title--placeholder'}`}>{headerTitle}</div>
      </div>

      <div className="nav-bar-new__section nav-bar-new__section--buttons">{rightHeaderSlot}</div>
    </div>
  )
}

export default NavBarNew;
import { useEffect, useState } from "react";
import "./index.scss";

import Facebook from "../../../img/facebook.svg";
import Google from "../../../img/google.svg";
import Sticker from "../../../img/sticker.svg";

import FacebookOn from "../../../img/facebookon.svg";
import GoogleOn from "../../../img/googleon.svg";
import StickerOn from "../../../img/stickeron.svg";

import iOSButton from "../../../img/iosOff.svg";
import androidButton from "../../../img/androidOff.svg";
import iOSButtonOn from "../../../img/iosOn.svg";
import androidButtonOn from "../../../img/androidOn.svg";

import machineIcon from "../../../img/icons/machine.svg";
import ordersIcon from "../../../img/icons/order.svg";
import cardIcon from "../../../img/icons/card.svg";
import marketingIcon from "../../../img/icons/marketing.svg";
import listIcon from "../../../img/icons/list.svg";
import userIcon from "../../../img/icons/user.svg";
import noLogo from "../../../img/noLogo.svg";
import t2sIcon from "../../../img/T2s.svg";
import v2Icon from "../../../img/v2.svg";
import FavouriteActive from "../../../img/favouriteActive.svg";
import FavouriteInactive from "../../../img/favouriteInactive.svg";

import moment from "moment";

import Modal from "react-modal";
import { sumBy } from "lodash";

import SimpleModal from "../../modals/simple-modal";
import {Admin} from '../../../fmo-api-sdk';
import { useSelector } from "react-redux";
import { getUserId } from "../../../app/auth";


Modal.setAppElement('#root');

const Overview = ({ shop, shopData }: { shop: Admin.ShopOverview, shopData: Admin.AdminShop }) => {

    const [showModal, setShowModal] = useState<boolean>(false);
    const [isFavourite, setIsFavourite] = useState<boolean>(false);

    const token = useSelector((state: any) => state.auth.token);
    const userId = getUserId(token)

    const sendTest = async (shop_id: string, type: string): Promise<void> => {
        const res = await Admin.sendTest(shop_id, type);

        if (res) alert("success")
        else alert("error");
    }

    const getFavourites = async() => {
        await Admin.getFavourites(userId)
            .then(res => {
                if (res.shop_favourites.includes(shop.wholesaler_id)) setIsFavourite(true);
                else setIsFavourite(false);
            })
          .catch(error => console.log(error));
    }

    const handleFavouriteclick = async() => {
        if (isFavourite) {
            await Admin.removeFavourite(shop.wholesaler_id)
                .then(() => setIsFavourite(false))
                .catch(error => alert(error));
        } else {
            await Admin.addFavourite(shop.wholesaler_id)
                .then(() => setIsFavourite(true))
                .catch(error => alert(error));
        }
    };

    useEffect(() => {
      getFavourites();
    }, [])
    
    // Card for cash & carry list
    const ModalCard = ({ cc }: { cc: Admin.CashAndCarry }) => (
        <>
            <div className="modalCard">
                <div className="modalCardName">{cc.wholesaler_name}</div>
                <div className="modalCardContent">{cc.wholesaler_address}</div>
                {cc.wholesaler_tel ? 
                <div className="modalCardPhone">{cc.wholesaler_tel}</div>
                : ""}

            </div>
        </>
    );
    
    //prevent scrolling when modal is open
    const windowWidth = window.innerWidth;
    let mainContent = document.getElementById("overview-page");
    const preventScrolling = function (showModal) {
        if (showModal) {
            document.body.classList.add("no-scroll");
            if (windowWidth > 1200) {
                mainContent?.classList.add("jitter-fix");
            }
        }
        else {
            document.body.classList.remove("no-scroll");
            mainContent?.classList.remove("jitter-fix");
        }
    }

    preventScrolling(showModal);

    const renderApps = function() {
        const appPlatforms = ['Android', 'iOS']

        return (
            <div>
                <div className="label promo-label">Apps</div>
                <div className="ov-info-badges flex-row">

                {appPlatforms.map(app => {
                    var platform = app === 'Android' ? 'wholesaler_app_android' : 'wholesaler_app_iphone'
                    return (
                        shop?.[platform] ?
                            <a href={shop?.[platform]} target="_blank" rel="noreferrer">
                                <div className="ov-apps-button-on">
                                    <img src={app === "Android" ? androidButtonOn : iOSButtonOn} alt={`${app} icon`} />
                                    <div>{app}</div>
                                </div>
                            </a>
                            :
                            <div className="ov-apps-button-off icon-off">
                                <img src={app === "Android" ? androidButton : iOSButton} alt={`${app} icon`} />
                                <div>{app}</div>
                            </div>
                    )
                })}
                </div>
            </div>
        );
    };

    //render promo
    const renderPromo = function(shop) {
        let classes = "ov-info-badge ov-promo"

        let stickerText = "";
        let stickerClasses = classes;
        let stickerIcon = "";

        let facebookText = "";
        let facebookClasses = classes;
        let facebookIcon = "";

        let gListingText = "";
        let gListingClasses = classes;
        let gListingIcon = "";

        if (shop?.promotion.stickers === true) {
            stickerText = "Sticker Installed";
            stickerIcon = StickerOn;
        }
        else {
            stickerText = "No Sticker";
            stickerClasses = classes + " ov-promo-missing";
            stickerIcon = Sticker;
        }

        if (shop?.promotion.facebook === true) {
            facebookText = "Facebook Page";
            facebookIcon = FacebookOn;
        }
        else {
            facebookText = "No Facebook Page";
            facebookClasses = classes + " ov-promo-missing";
            facebookIcon = Facebook;
        }

        if (shop?.promotion.google === true) {
            gListingText = "Google Listing";
            gListingIcon = GoogleOn;
        }
        else {
            gListingText = "No Google Listing";
            gListingClasses = classes + " ov-promo-missing";
            gListingIcon = Google;
        }

        return (
            <div>
                <div className="label promo-label">Promo</div>
                <div className="ov-info-badges flex-row">
                    <div className={gListingClasses}><img src={gListingIcon} alt="gListing Svg" />{gListingText}</div>
                    <div className={facebookClasses}><img src={facebookIcon} alt="Facebook Svg" />{facebookText}</div>
                    <div className={stickerClasses}><img src={stickerIcon} alt="Sticker Svg" />{stickerText}</div>
                </div>
            </div>
        )

    }

    // render devices owned by shop
    function renderDevices() {
        {/* @ts-ignore */}
        var devicesData = shopData?.machine?.devices
        var shopDevices;

        if(devicesData !== undefined){

        }
        const hasT2s= devicesData !== undefined ? devicesData.some(device => device.device_type === 'epos') : false;
        const hasV2 = devicesData !== undefined ? devicesData.some(device => device.device_type === 'smartlink') : false;

        if (hasT2s && hasV2) shopDevices = 'Both';
        else if (hasV2) shopDevices = 'V2';
        else if (hasT2s) shopDevices = 'T2s'
        else shopDevices = 'None';

        return (
            <>
                {(shopDevices === 'V2' || shopDevices === 'Both') &&
                    <div className="ov-info-badge ov-device">
                        <img src={v2Icon} alt="Shop has V2" className="ov-device--v2" />
                        V2
                    </div>
                }
                {(shopDevices === 'T2s' || shopDevices === 'Both') &&
                    <div className="ov-info-badge ov-device">
                        <img src={t2sIcon} alt="Shop has T2s" className="ov-device--t2s" />
                        T2s
                    </div>
                }
            </>
        )
    }

    //render joined 
    const renderJoined = function (shop) {
        const validDate = moment(shop?.wholesaler_created).isValid();
        let text = "Old Shop";
        if (validDate === true) {
            text = "Joined " + moment(shop?.wholesaler_created).format("MMM YYYY");
        }
        return (
            <div className="ov-info-badge ov-info-joined">{text}</div>
        )                        
    }

    //render discount 
    const renderDiscount = function (shop) {
        let classes = "ov-info-badge ov-discount";
        let label = shop?.wholesaler_discount + "% Discount";

        if (shop?.wholesaler_discount == 0) {
            classes = classes + " ov-no-discount";
            label = "No Discount"
        }
        return (
            <div className={classes}>{label}</div>
        )
    }

    //render package 
    const renderPackage = function (shop) {
        let classes = "ov-info-badge ov-package";
        let label = "8% Package";

        if (shop.three_months_free === "Yes") {
            label = "Free Trial";
            classes = classes + " ov-package-free";
        } else {
          if (shop.wholesaler_fee === "0.000") label = "Direct Debit";
          else if (shop.wholesaler_fee === "0.032") label = "3.2% Package";
        }

        return (
            <div className={classes}>{label}</div>
        );
    }

    const CashCarryModal = () => (
        <SimpleModal isOpen={showModal} title="Cash & Carry List" setClosed={() => setShowModal(false)}>
            <div className="CCModal">
                <div className="badge">{shop?.wholesaler_name}</div>
                <div className="CCs">
                    {shop?.cash_and_carry.map((cc, index) => <ModalCard key={index} cc={cc}/>)}
                </div>
            </div>
        </SimpleModal>
    );

    const {cc_account = 0, cc_password = 0, user_id = 0, password = 0} = (shop || {});

    // Check if the shop existed before last month
    const lastDayOfPrevMonth = moment().subtract(1,'months').endOf('month');
    const momentShopCreated = moment(shop.created_on);
    const showPrevMonthOrders = lastDayOfPrevMonth.isBefore(momentShopCreated) ? true : false;

    // @ts-ignore
    return (
        <div className="overview-grid">
            <CashCarryModal />

            <div className="overview-content">
                    <div className="ov-info ov-section">
                        
                        <div className="ov-info-head-wrapper flex-row">
                            <div className="ov-info-head">
                                <div className="ov-info-head-id">{shop?.wholesaler_id}</div>
                                <div className="ov-name">{shop?.wholesaler_name}</div>
                            </div>
                            <div className="ov-favourite-wrapper">
                                <img
                                    src={isFavourite ? FavouriteActive : FavouriteInactive}
                                    alt={isFavourite ? "Favourite shop" : "Shop not favourited"}
                                    onClick={() => handleFavouriteclick()}
                                    className="ov-favourite" />
                            </div>
                        </div>

                        <div className="flex-row ov-info-badges">
                            {/* <div className="ov-info-badge ov-info-joined">
                            Joined {moment(shop?.wholesaler_created).format("MMM YYYY")}</div> */}
                            {renderDevices()}

                            {renderJoined(shop)}  
                            <div className="ov-info-badge-group">  
                                {renderDiscount(shop)}
                                {renderPackage(shop)}
                            </div>
    
                        </div>

                        <div className="ov-info-main">

                            <div className="ov-info-img">
                                <img src={`https://images.feedmeonline.co.uk/logos/${shop?.wholesaler_id}.png`} onError={e => e.currentTarget.src = noLogo} alt="" />
                                {/* <div className="flex-row ov-promo">
                                    <img className={"promo_icon " + (shop?.promotion.facebook ? '' : 'icon-off')} src={shop?.promotion.facebook ? FacebookOn : Facebook} alt="FB Svg" />
                                    <img className={"promo_icon " + (shop?.promotion.google ? '' : 'icon-off')} src={shop?.promotion.google ? GoogleOn : Google} alt="Google Svg" />
                                    <img className={"promo_icon " + (shop?.promotion.stickers ? '' : 'icon-off')} src={shop?.promotion.stickers ? StickerOn : Sticker} alt="Sticker Svg" style={{ fill: "red" }} />
                                </div> */}
                            </div>

                            <div className="ov-info-col">
                                
                                <div className="ov-info-text">
                                    <div>Owner</div>
                                    <div>{shop?.info.contact_name}</div>
                                </div>
                                <div className="ov-info-text">
                                    <div>Mobile</div>
                                    <div>{shop?.wholesaler_mobile}</div>
                                </div>
                                <div className="ov-info-text">
                                    <div>Telephone</div>
                                    <div>{shop?.wholesaler_tel}</div>
                                </div>

                            </div>

                            <div className="ov-info-col">
                               
                               <div className="ov-info-text">
                                   <div>Address</div>
                                   <div>{shop?.wholesaler_address}</div>
                               </div>
                               <div className="ov-info-text">
                                   <div>Email</div>
                                   <div>{shop?.info.email_address}</div>
                               </div>
                               <div className="ov-info-text">
                                   <div>Domain</div>
                                   <div><a href={`http://${shop?.wholesaler_domain_name}`} rel="noreferrer" target="_blank">{shop?.wholesaler_domain_name?.replace("www.", "").replace("http://", "").replace("https://", "")}</a></div>
                               </div>

                           </div>

                        </div> 

                        <div className="ov-apps">
                            {renderApps()}
                            {renderPromo(shop)}
                        </div>

                    </div>
                
                    <div className="machine ov-section">

                        <div className="section-title">
                            <div className="icon">
                                <img src={machineIcon} alt="machine icon"/>
                            </div>
                            <h3>Machine</h3>
                        </div>

                        <div className="machine-info">

                            <div className="machine-actions">
                                Handle Test Orders 
                                <div className="ov-buttons">
                                        <button style={{ background: 'white', color: '#D82B31', border: '1px solid #FCD7D4' }} onClick={() => sendTest(shop.wholesaler_id, "order")}>Send Test Order</button>
                                        <button style={{ background: '#FCD7D4', color: '#D82B31' }} onClick={() => sendTest(shop?.wholesaler_id, "push")}>Push Notification</button>
                                        {/*<button style={{ background: '#e7dafc', color: '#5F09EB' }} onClick={() => sendTest(shop?.wholesaler_id, "offer")}>Push Test Offer</button>*/} 
                                </div>
                            </div>

                            <div className="ov-login">
                                <div>Machine Login</div>
                                <div>
                                    L:&nbsp;{user_id === 0 ? "N/A" : user_id}<br />
                                    P:&nbsp;{password === 0 ? "N/A" : password}
                                </div>
                            </div>

                            <div className="ov-login ov-cc">
                                <div>Control Centre</div>
                                <div>
                                    L:&nbsp;{cc_account === 0 ? "N/A" : cc_account}<br />
                                    P:&nbsp;{cc_password === 0 ? "N/A" : cc_password}
                                </div>
                            </div>

                            <div className={"ov-machine-ping ov-stat " + 
                            (moment(shop?.orders.last_checked).fromNow().includes("months") 
                            || moment(shop?.orders.last_checked).fromNow().includes("days") ? "ov-stat-red" :
                            (shop?.orders.last_checked === null) ? "ov-stat-grey" : "ov-stat-green")}>
                                <div>Last Ping</div>
                                <div>{shop?.orders.last_checked ? moment(shop?.orders.last_checked).fromNow() : 'Never'}</div>
                            </div>

                        </div>

                    </div>

                    <div className="wrapper1">

                        <div className="ov-customers ov-section">
                            <div className="section-title">
                                <div className="icon">
                                    <img src={userIcon} alt="customers icon"/>
                                </div>
                                <h3>Customers</h3>
                            </div>

                            <div className="ov-customer-stats">

                                <div className={"ov-stat ov-stat-large ov-stat-grey " + 
                                (shop?.customers.total == 0 ? "ov-stat-red" : "")}>
                                    <div>Total</div>
                                    <div>{shop?.customers.total.toLocaleString()}</div>
                                </div>

                                <div className={"ov-stat ov-stat-large ov-stat-grey " + 
                                (shop?.customers.total_ordering == 0 ? "ov-stat-red" : "")}>
                                    <div>Ordering</div>
                                    <div>{shop?.customers.total_ordering.toLocaleString()}</div>
                                </div>

                            </div>
                        </div>

                        <div className="ov-orders ov-section">
                            <div className="section-title">
                                <div className="icon">
                                    <img src={ordersIcon} alt="orders icon"/>
                                </div>
                                <h3>Orders</h3>
                            </div>

                            <div className="ov-orders-grid">
                                <div className={"ov-stat " + (shop?.orders.today === 0 ? "ov-stat-red" : "ov-stat-grey")}>
                                    <div>Today</div>
                                    <div>{shop?.orders.today.toLocaleString()}</div>
                                </div>

                                <div className={"ov-stat " + (shop?.orders.this_week === 0 ? "ov-stat-red" : "ov-stat-grey")}>
                                    <div>7 days</div>
                                    <div>{shop?.orders.this_week.toLocaleString()}</div>
                                </div>
                                <div className={"ov-stat " + (shop?.orders.this_month === 0 ? "ov-stat-red" : "ov-stat-grey")}>
                                    <div>this month</div>
                                    <div>{shop?.orders.this_month.toLocaleString()}</div>
                                </div>
                                <div className={"ov-stat " + (showPrevMonthOrders ? "ov-stat-disabled" : shop?.orders.last_month === 0 ?  "ov-stat ov-stat-red" : "ov-stat ov-stat-grey")}>
                                    <div>last month</div>
                                    <div>{shop?.orders.last_month.toLocaleString()}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wrapper2">
                        
                        <div className="ov-apCard ov-section">
                            
                            <div className="section-title">
                                <div className="icon">
                                    <img src={cardIcon} alt="card icon"/>
                                </div>
                                <h3>AP Card</h3>
                                <button className="ccList" onClick={() => setShowModal(true)}>
                                    CC List
                                    <img src={listIcon} alt="list icon"/>
                                </button>
                            </div>

                            <div className="ov-card-stats stats-row">

                                <div className={"ov-stat " + (Number(shop?.info.account_balance) <= 0  ? "ov-stat-red" : "ov-stat-grey" )}>
                                    <div>Account Balance</div>
                                    <div>£{parseFloat(shop?.info.account_balance).toLocaleString()}</div>
                                </div>

                                <div className={"ov-stat " + (sumBy(shop?.cash_and_carry, (cc) => parseFloat(cc.spent)).toLocaleString() === 0 ? "ov-stat-red" : "ov-stat-grey")}>
                                    <div>Money Spent</div>
                                    <div>£{sumBy(shop?.cash_and_carry, (cc) => parseFloat(cc.spent)).toLocaleString()}</div>
                                </div>

                            </div>

                        </div>

                        <div className="ov-marketing ov-section">
                            
                            <div className="section-title">
                                <div className="icon">
                                    <img src={marketingIcon} alt="marketing icon"/>
                                </div>
                                <h3>Marketing</h3>
                            </div>

                            <div className="ov-marketing-stats stats-row">

                                <div className={"ov-stat " + (shop?.sms.today === 0 ? "ov-stat-red" : "ov-stat-green")}>
                                    <div>SMS Sent Today</div>
                                    <div>{shop?.sms.today}</div>
                                </div>

                                <div className="ov-stat ov-stat-grey">
                                    <div>Last Campaign</div>
                                    <div>{shop?.sms.last_campaign ? moment(shop?.sms.last_campaign).format("DD/MM/YY") : 'N/A'}</div>
                                </div>

                            </div>

                        </div>

                 </div>

            </div>
        </div >
    )
}

export default Overview;

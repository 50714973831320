import React, { useEffect, useState } from "react";
import "./index.scss";
import { pickBy, isEmpty, without } from "lodash";
import parsePhoneNumber from 'libphonenumber-js';
import { store } from "../../app/store";
import SimpleModal from "../modals/simple-modal";
import { useHistory } from "react-router-dom";
import { CashCarry, Admin } from '../../fmo-api-sdk';

import ArrowRight from "../../img/arrowRight.svg";
import ArrowWhite from "../../img/arrowRightWhite.svg";
import ArrowLeftRed from "../../img/arrowLeftRed.svg";
import drownDownArrow from "../../img/dropdownArrow.svg";
import Disclaimer from "../../img/disclaimer.svg";
import useWindowSize from "../../app/helper/screen";
import { PuffLoader } from "react-spinners";
import { getUserId } from "../../app/auth";
import { useSelector } from "react-redux";

const nameReg = /[^\x00-\x80\xA3]+/;
const postcodeReg = /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/;
const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const domainReg = /\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/;

const CreateShop = () => {
    const history = useHistory();

    const [tab, setTab] = useState(0);
    const [form, setForm] = useState<any>({
        name: "",
        mobile: "",
        mobile2: "",
        region: "",
        ownerName: "",
        postcode: "",
        landline: "",
        email: "",
        address: "",
        trial: "Yes",
        logo: "",
        menu: "",
        domain: "",
        security_word: ""
    });

    const [brands, setBrands] = useState({
        flour: "",
        cheese: "",
        chips: "",
        donner: "",
        oil: "",
        currentCC: ""
    })

    const [ccList, setList] = useState<any>([]);
    const [selectedCC, setCC] = useState<any>([]);
    const [completeModal, setCompleteModal] = useState<boolean>(false);
    const [result, setResult] = useState<any>();
    const [error, setError] = useState<boolean>(false);
    const [submittingForm, setSubmittingForm] = useState<boolean>(false);
    const token = useSelector((state: any) => state.auth.token);
    const userId = getUserId(token)

    //prevent scrolling when modal is open
    const windowWidth = window.innerWidth;
    const preventScrolling = function (completeModal) {
        if (completeModal) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }

    preventScrolling(completeModal);

    //tab dropdown
    const size = useWindowSize();
    const [dropdownToggle, setDropdownToggle] = useState<boolean>(false);
    const handleFirstTab = function () {
        setDropdownToggle(false);
        setTab(0);
        window.scrollTo(0,0);
    }
    const handleSecondTab = function () {
        setDropdownToggle(false);
        setTab(1);
        window.scrollTo(0,0);
    }

    const [errorList, setErrorList] = useState<any>({
        1: "",
        2: "",
        3: "",
        4: ""
    });

    useEffect(() => {
        (async function () {
            if (ccList.length === 0) {
                const data = await CashCarry.getAll();
                setList(data);
            }
        })();
    });

    const onNextPress = () => {
        if (tab === 1) {
            submitForm();
            return;
        }

        var isError = false;
        setError(false);
        setErrorList({
            1: "",
            2: "",
            3: "",
            4: ""
        })

        const errorListTemp = {
            1: "done",
            2: "done",
            3: "done",
            4: "done"
        };

        if (tab === 0) {
            errorListTemp[3] = "";
            errorListTemp[4] = "";
        }

        const errors = document.getElementsByClassName("inputError");
        while (errors.length) errors[0].className = "";

        const empty = pickBy(form, (v) => isEmpty(v));

        for (const [k,] of Object.entries(empty)) {
            if (k === "mobile2" || k === "domain") continue;
            const el = document.getElementById(k);
            if (el) el.className = "inputError";

            if (k === "ownerName" || k === "mobile" || k === "email") errorListTemp[1] = "error";
            else errorListTemp[2] = "error";
        }

        // Replace bad characters for names
        form.ownerName = form.ownerName.replace("’", "'");
        form.ownerName = form.ownerName.replace('“', '"');
        form.name = form.name.replace("’", "'");
        form.name = form.name.replace('“', '"');

        // Validate owner name
        if (nameReg.test(form.ownerName)) {
            (document.getElementById("ownerName") as any).className = "inputError";
            isError = true;
            errorListTemp[1] = "error";
        }

        // Validate shop name
        if (nameReg.test(form.name)) {
            (document.getElementById("name") as any).className = "inputError";
            isError = true;
            errorListTemp[1] = "error";
        }

        // Validate phone numbers
        if (!parsePhoneNumber(form.mobile, 'GB')?.isValid()) {
            (document.getElementById("mobile") as any).className = "inputError";
            isError = true;
            errorListTemp[1] = "error";
        }

        if (!parsePhoneNumber(form.landline, 'GB')?.isValid()) {
            (document.getElementById("landline") as any).className = "inputError";
            isError = true;
            errorListTemp[2] = "error";
        }

        // Validate postcode
        if (!postcodeReg.test(form.postcode)) {
            (document.getElementById("postcode") as any).className = "inputError";
            isError = true;
            errorListTemp[2] = "error";
        }

        // Validate region/city

        // Validate email
        if (!emailReg.test(form.email)) {
            (document.getElementById("email") as any).className = "inputError";
            isError = true;
            errorListTemp[1] = "error";
        }

        if (form.domain && !domainReg.test(form.domain)) {
            (document.getElementById("domain") as any).className = "inputError";
            isError = true;
            errorListTemp[2] = "error";
        }

        setErrorList(errorListTemp);
        if (isError) { setError(true); return; }
        else setTab(1);
    };

    function getFormData(object) {
        const formData = new FormData();
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    const submitForm = async () => {
        setSubmittingForm(true);
        const send = {
            ...form,
            agent: userId,
            availableCcs: selectedCC.join(","),
            user: "",
            accountType: "",
            activatedBy: "",
            machineDeposit: "",
            liteShop: "",
            brands: JSON.stringify(brands)
        }

        const formData = getFormData(send);

        const res = await Admin.createShop(formData);
        setResult(res);
        setSubmittingForm(false);
        setCompleteModal(true);
    }

    const onUpdateForm = (e) => setForm({ ...form, [e.target.id]: e.target.value });
    const onUpdateBrands = e => setBrands({ ...brands, [e.target.id]: e.target.value });

    const updateTrial = () => {
        if (form.trial === "Yes") {
            setForm({ ...form, trial: "No" });
        }
        if (form.trial === "No") {
            setForm({ ...form, trial: "Yes" });
        }
    }

    const ccClick = (id) => {
        if (selectedCC.includes(id)) setCC(without(selectedCC, id))
        else setCC([...selectedCC, id]);
    }

    const CompleteModal = () => (
        <SimpleModal isOpen={completeModal} title="" setClosed={() => setCompleteModal(false)}>
            <div className="CreateShopCompleteModal">
                <div className="header">Account Created
                    <div className="fmoID">{result?.fmoId}</div>
                </div>

                <div className="shop-info">
                    <div>{form.name}</div>
                    <div className="shop-info-sub">has been added to the FMO platform.</div>
                </div>

                <hr></hr>

                <div className="resultsWrapper">

                    <div className="subtext">Please find the machine login details below.</div>

                    <div className="results">
                        <div className="results-item">
                            <div>Account Num</div>
                            <div>{result?.fmoId}</div>
                        </div>
                        <div className="results-item">
                            <div>User ID</div>
                            <div>{result?.ccId}</div>
                        </div>
                        <div className="results-item">
                            <div>Password</div>
                            <div>{result?.password}</div>
                        </div>
                    </div>
                </div>

                <div className="buttonGroup">
                    <button onClick={() => history.push("/home")}><img src={ArrowLeftRed} alt=""/>Back To Home Screen</button>
                    <button onClick={() => history.push(`/overview/${result?.fmoId}`)}>Shop Overview</button>
                </div>
            </div>
        </SimpleModal>
    );

    return (
        <div id="CreateShop" style={completeModal && windowWidth > 1200 ? {right: 12} : {}}>
            <CompleteModal/>

            <div className="container" >
                {size.width !== undefined && size.width <= 768 ? 
                    (<div className={"tabs-dropdown-wrapper " + (dropdownToggle? "tabs-dropdown-wrapper-active" : "")}>
                        <div className="tabs-dropdown">
                            <div className={"tabs-dropdown-button " + (dropdownToggle? "tabs-dropdown-button-active" : "")}  
                            onClick={() => setDropdownToggle(!dropdownToggle)}>
                                {tab === 0 ? "Account Details" : "Cash & Carry"}
                                <img src={drownDownArrow} alt="expand arrow"/>
                            </div>
                            { dropdownToggle ? 
                            <div className="dropdown-tabs-list">
                                <div className={"tab1 " + (tab === 0 ? "activeTab" : "")} onClick={() => handleFirstTab()}>Account Details</div>
                                <div className={"tab2 " + (tab === 1 ? "activeTab" : "")} onClick={() => handleSecondTab()}>Cash &amp; Carry</div>
                                <div className="progress">
                                    <div className="text">Progress</div>
                                    <div className="circleGroup">
                                        <div className={"circle " + errorList[1]}>1</div>
                                        <div className={"circle " + errorList[2]}>2</div>
                                        <div className={"circle " + errorList[3]}>3</div>
                                        <div className={"circle " + errorList[4]}>4</div>
                                    </div>
                                </div>
                            </div>
                            : "" }
                        </div>
                    </div>
                ) : (
                <div className="sticky-tabs">
                        <div className={"tab1 " + (tab === 0 ? "activeTab" : "")} onClick={() => setTab(0)}>Account Details</div>
                        <div className={"tab2 " + (tab === 1 ? "activeTab" : "")} onClick={() => setTab(1)}>Cash &amp; Carry</div>
                        <div className="progress">
                            <div className="text">Progress</div>
                            <div className="circleGroup">
                                <div className={"circle " + errorList[1]}>1</div>
                                <div className={"circle " + errorList[2]}>2</div>
                                <div className={"circle " + errorList[3]}>3</div>
                                <div className={"circle " + errorList[4]}>4</div>
                            </div>
                        </div>
                </div>
                )}

                {tab === 0 ?
                    <div className="details">
                        <div className="section">

                            <div className="detailsHeader" style={{ marginTop: 0 }}>
                                <div className={"circle " + errorList[1]}>1</div>
                                <div>Owner Details</div>
                            </div>
                            <div className="inputGroup">
                                <div className="inputCol">
                                    <div className="floatInput">
                                        <input type="text" id="ownerName" placeholder="Full Name" value={form.ownerName} onChange={onUpdateForm} />
                                    </div>
                                    <div className="floatInput">
                                        <input type="email" id="email" placeholder="Email" value={form.email} onChange={onUpdateForm} />
                                    </div>
                                </div>

                                <div className="inputCol">
                                    <div className="floatInput">
                                            <input type="tel" id="mobile" placeholder="Mobile" value={form.mobile} onChange={onUpdateForm} />
                                    </div>
                                    <div className="floatInput">
                                        <input type="tel" id="mobile2" placeholder="Mobile Alt (optional)" value={form.mobile2} onChange={onUpdateForm} />
                                    </div>
                            </div>
                        </div>
                    </div>


                    <div className="section">
                        <div className="detailsHeader">
                            <div className={"circle " + errorList[2]}>2</div>
                            <div>Shop Details</div>
                        </div>

                        <div className="inputRadio">
                            <label className="label">Choose Package</label>
                            <span className="choosePackage" onClick={() => updateTrial()}>
                                <div className={"packageOption " + (form.trial === "Yes" ? "packageActive" : "")}>FREE</div>
                                <div className={"packageOption " + (form.trial === "No" ? "packageActive" : "")}>8%</div>
                            </span>
                        </div>
                        

                        <div className="inputGroup">
                            <div className="inputCol">
                                <div className="floatInput">
                                    <input type="text" id="name" placeholder="Shop Name" value={form.name} onChange={onUpdateForm} />
                                </div>
                                <div className="floatInput">
                                    <input type="tel" id="landline" placeholder="Landline Number" value={form.landline} onChange={onUpdateForm} />
                                </div>
                                <div className="floatInput">
                                    <input type="text" id="security_word" placeholder="Security Word (required)" value={form.security_word} onChange={onUpdateForm} />
                                </div>
                            </div>
                            <div className="inputCol">
                                <div className="floatInput">
                                    <input type="text" id="address" placeholder="Address" value={form.address} onChange={onUpdateForm} />
                                </div>
                                <div className="floatInput">
                                    <input type="text" id="region" placeholder="Region/City" value={form.region} onChange={onUpdateForm} />
                                </div>
                                <div className="floatInput">
                                    <input type="text" id="postcode" placeholder="Postcode" value={form.postcode} onChange={onUpdateForm} />
                                </div>
                            </div>
                        </div>

                        <div className="inputRadio">
                            <div className="detailsTitle">Menu Source<span className="labelRequirement">(required)</span></div>

                            <div className="checkInputs">
                                <div className={"checkbox" + (form.menu === "paper" ? " cc-active" : "")} onClick={() => setForm({ ...form, menu: "paper" })}>
                                    <div>Paper</div>
                                </div>

                                <div className={"checkbox" + (form.menu === "justeat" ? " cc-active" : "")} onClick={() => setForm({ ...form, menu: "justeat" })}>
                                    <div>JustEat</div>
                                </div>

                                <div className={"checkbox" + (form.menu === "foodhub" ? " cc-active" : "")} onClick={() => setForm({ ...form, menu: "foodhub" })}>
                                    <div>Foodhub</div>
                                </div>

                                <div className="floatInput">
                                    <input type="text" id="menu" value={form.menu !== "paper" && form.menu !== "justeat" && form.menu !== "foodhub"? form.menu : ""} onChange={onUpdateForm} placeholder="Other" />
                                </div>
                            </div>
                        </div>

                        <div className="inputRadio">
                            <div className="detailsTitle">Logo<span className="labelRequirement">(required)</span></div>
                            <div className="checkInputs">
                                <div className={"checkbox" + (form.logo === "paper" ? " cc-active" : "")} onClick={() => setForm({ ...form, logo: "paper" })}>
                                    <div>Paper</div>
                                </div>

                                <div className={"checkbox" + (form.logo === "justeat" ? " cc-active" : "")} onClick={() => setForm({ ...form, logo: "justeat" })}>
                                    <div>JustEat</div>
                                </div>


                                <div className={"checkbox" + (form.logo === "foodhub" ? " cc-active" : "")} onClick={() => setForm({ ...form, logo: "foodhub" })}>
                                    <div>Foodhub</div>
                                </div>

                                <div className="floatInput">
                                    <input type="text" id="logo" value={form.logo !== "paper" && form.logo !== "justeat" && form.logo !== "foodhub" ? form.logo : ""} onChange={onUpdateForm} placeholder="Other" />
                                </div>
                            </div>
                        </div>

                        <div className="floatInput">
                            <label className="label" htmlFor="domain">Preferred Domain</label>
                            <input type="text" id="domain" value={form.domain} onChange={onUpdateForm} />
                        </div>

                        <div className={error ? "nextButton buttonError" : "nextButton"}>
                            <button onClick={onNextPress}>
                                <div>{error ? "Please fill in all the required fields before you continue." : (tab === 0 ? "Assign Cash & Carries" : "Create Account")}</div>
                                <img src={error ? Disclaimer : ArrowRight} alt="right arrow" />
                            </button>
                        </div>

                    </div>

                </div>
                :
                <div className="details">
                    <div className="section">

                        <div className="detailsHeader">
                            <div className={"circle " + errorList[3]}>3</div>
                            <div>Assign Cash &amp; Carries</div>
                        </div>

                        <div className="ccRow">
                            {ccList.map((x, i) => {
                                return (
                                    <div className={"checkbox-cc " + (selectedCC.includes(x.wholesaler_id) ? "cc-active" : "")} key={x.wholesaler_id} onClick={() => ccClick(x.wholesaler_id)}>
                                        <div className="ccPostcode" dangerouslySetInnerHTML={{ __html: postcodeReg.test(x.wholesaler_postcode?.trim()) ? x.wholesaler_postcode?.trim().replace(" ", "<br />") : '' }}></div>
                                        <div>{x.wholesaler_name}</div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>

                    <div className="section">
                        <div className="detailsHeader">
                            <div className={"circle " + errorList[4]}>4</div>
                            <div>Preferred Brands</div>
                        </div>

                        <div className="inputGroup">

                            <div className="inputCol">
                                <div className="floatInput">
                                    <label htmlFor="flour" className="label">Flour</label>
                                    <input type="text" id="flour" value={brands.flour} onChange={onUpdateBrands} placeholder="" />
                                </div>
                                <div className="floatInput">
                                    <label htmlFor="cheese" className="label">Pizza Cheese</label>
                                    <input type="text" id="cheese" value={brands.cheese} onChange={onUpdateBrands} placeholder="" />
                                </div>
                                <div className="floatInput">
                                    <label htmlFor="chips" className="label">Chips</label>
                                    <input type="text" id="chips" value={brands.chips} onChange={onUpdateBrands} placeholder="" />
                                </div>
                            </div>

                            <div className="inputCol">
                                <div className="floatInput">
                                    <label htmlFor="donner" className="label">Donner Kebab</label>
                                    <input type="text" id="donner" value={brands.donner} onChange={onUpdateBrands} placeholder="" />
                                </div>
                                <div className="floatInput">
                                    <label htmlFor="oil" className="label">Oil</label>
                                    <input type="text" id="oil" value={brands.oil} onChange={onUpdateBrands} placeholder="" />
                                </div>
                                <div className="floatInput">
                                    <label htmlFor="currentCC" className="label">Current C&amp;C</label>
                                    <input type="text" id="currentCC" value={brands.currentCC} onChange={onUpdateBrands} placeholder="" />
                                </div>
                            </div>
                        </div>
                    
                        <div className={error ? "nextButton buttonError" : "nextButton2"}>
                            {submittingForm ? 
                            <div className="spinner-central"> 
                                <PuffLoader
                                    size={75}
                                    color={"#D82B31"}
                                    loading={true}
                                />
                            </div> 
                                            :
                            <button onClick={onNextPress}>
                                <div>{error ? "Please fill in all the required fields before you continue" : (tab === 0 ? "Assign Cash & Carries" : "Create Account")}</div>
                                <img src={error ? Disclaimer : ArrowWhite} alt="right arrow" />
                            </button>
                            }
                        </div>
                    </div>
                </div>
            }
            </div>
        </div >
    )
}

export default CreateShop;

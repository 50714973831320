import { Dispatch, SetStateAction, useEffect, useState } from "react";
import TextField from "../../text-field";
import UserInputHeader from "../../user-input-header";
import FormSection from "./form-section";
import './index.scss';
import { hasManagerAccess } from "../../../assigneeData";
import { getUserId } from "../../../app/auth";
import { useSelector } from "react-redux";

type Props = {
  setFormData: Dispatch<any>
  formErrors?: string
} & (
  {
    prospectData?: never,
    setSaveEnabled?: never,
  } | {
    prospectData: any,
    setSaveEnabled: Dispatch<SetStateAction<boolean>>,
  }
)

const ProspectForm = ({prospectData, setSaveEnabled, setFormData, formErrors}: Props) => {
  const token = useSelector((state: any) => state.auth.token);
  const userID = getUserId(token)

  const [shopName, setShopName] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [landlineNumber, setLandlineNumber] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [shopAddress, setShopAddress] = useState('');
  const [location, setLocation] = useState('');
  const [shopPostcode, setShopPostcode] = useState('');
  const [shopScore, setShopScore  ] = useState('');

  const [existingPlatforms, setExistingPlatforms] = useState<string[]>([]);
  const [otherPlatforms, setOtherPlatforms] = useState('');

  const [justEatOrders, setJustEatOrders] = useState('');
  const [deliverooOrders, setDeliverooOrders] = useState('');
  const [uberEatsOrders, setUberEatsOrders] = useState('');
  const [foodHubOrders, setFoodHubOrders] = useState('');
  const [orderYoyoOrders, setOrderYoyoOrders] = useState('');
  const [flipDishOrders, setFlipDishOrders] = useState('');
  const [otherOrders, setOtherOrders] = useState('');

  const [currentEposProvider, setCurrentEposProvider] = useState<string[]>([]);
  const [otherProviders, setOtherProviders] = useState('');

  const [cashAndCarries, setCashAndCarries] = useState('');

  const shopInfo = [
    {label: 'Shop Name', value: shopName, onChange: setShopName},
    {label: 'Owner Name', value: ownerName, onChange: setOwnerName},
    {label: 'Mobile Number', value: mobileNumber, onChange: setMobileNumber},
    {label: 'Landline', value: landlineNumber, onChange: setLandlineNumber},
    {label: 'Email', value: ownerEmail, onChange: setOwnerEmail},
    {label: 'Address', value: shopAddress, onChange: setShopAddress},
    {label: 'Location', value: location, onChange: setLocation},
    {label: 'Postcode', value: shopPostcode, onChange: setShopPostcode},
    {label: 'Score', value: shopScore, onChange: setShopScore}
  ];

  const platformData = [
    {label: 'JustEat', value: justEatOrders, onChange: setJustEatOrders},
    {label: 'Deliveroo', value: deliverooOrders, onChange: setDeliverooOrders},
    {label: 'UberEats', value: uberEatsOrders, onChange: setUberEatsOrders},
    {label: 'FoodHub', value: foodHubOrders, onChange: setFoodHubOrders},
    {label: 'OrderYoyo', value: orderYoyoOrders, onChange: setOrderYoyoOrders},
    {label: 'FlipDish', value: flipDishOrders, onChange: setFlipDishOrders},
  ];

  const platformList = platformData.map(platform => platform.label);

  const eposProviderData = ['FoodHub', 'OrderYoyo', 'Flipdish', 'Square', 'Grafterr', 'Epos Now'];

  useEffect(() => {
    if (prospectData) {
      const parsedPlatforms = JSON.parse(prospectData.existing_platforms);
      const parsedProviders = JSON.parse(prospectData.existing_epos_providers);

      setOtherPlatforms(parsedPlatforms?.find(platform => !platformList.includes(platform.name))?.name);
      setOtherOrders(parsedPlatforms?.find(platform => !platformList.includes(platform.name))?.order_volume);
      setOtherProviders(parsedProviders?.find(provider => !eposProviderData.includes(provider)) ?? '');

      setExistingPlatforms(parsedPlatforms?.map(platform => platform?.name) ?? []);
      setCurrentEposProvider(parsedProviders ?? []);
      setShopName(prospectData.shop_name);
      setOwnerName(prospectData.owner_name);
      setMobileNumber(prospectData.mobile);
      setLandlineNumber(prospectData.landline);
      setOwnerEmail(prospectData.email);
      setShopAddress(prospectData.address);
      setLocation(prospectData.town);
      setShopPostcode(prospectData.postcode);
      setShopScore(prospectData.matrix_value);

      setJustEatOrders(parsedPlatforms?.find(platform => platform.name === 'JustEat')?.order_volume?.toString() ?? '');
      setDeliverooOrders(parsedPlatforms?.find(platform => platform.name === 'Deliveroo')?.order_volume?.toString() ?? '');
      setUberEatsOrders(parsedPlatforms?.find(platform => platform.name === 'UberEats')?.order_volume?.toString() ?? '');
      setFoodHubOrders(parsedPlatforms?.find(platform => platform.name === 'FoodHub')?.order_volume?.toString() ?? '');
      setOrderYoyoOrders(parsedPlatforms?.find(platform => platform.name === 'OrderYoyo')?.order_volume?.toString() ?? '');
      setFlipDishOrders(parsedPlatforms?.find(platform => platform.name === 'FlipDish')?.order_volume?.toString() ?? '');

      setCashAndCarries(prospectData.preferred_cc);
    };
  }, [prospectData]);


  useEffect(() => {
    //below sets update payload
    let tempPlatforms = [] as any;
    existingPlatforms.filter(platform => platformList.includes(platform)).forEach(selectedPlatform => {
      tempPlatforms.push({name: selectedPlatform, order_volume: platformData.find(platform => platform.label === selectedPlatform)?.value})
    })
    if (otherPlatforms) {
      tempPlatforms.push({name: otherPlatforms, order_volume: otherOrders})
    }

    var tempProviders = currentEposProvider?.filter(provider => eposProviderData.includes(provider));

    if (otherProviders) {
      tempProviders.push(otherProviders);
    }

    const formData = {
      name: shopName,
      ownerName: ownerName,
      mobile: mobileNumber,
      landline: landlineNumber,
      email: ownerEmail,
      address: shopAddress,
      town: location,
      postcode: shopPostcode,
      matrixValue: shopScore,
      existingPlatforms: tempPlatforms,
      status: '',
      assignee: prospectData?.assignee ?? userID,
      existingEposProviders: tempProviders,
      preferred_cc: cashAndCarries
    }

    setFormData(formData);

    // Below determines if changes made
    if (prospectData && setSaveEnabled) {
      const parsedPlatforms = JSON.parse(prospectData?.existing_platforms) ?? [];
      const parsedProviders = JSON.parse(prospectData?.existing_epos_providers) ?? [];

      const compareOrders = (namedPlatform, platformOrders) => {
        var temp = parsedPlatforms?.find(platform => platform.name === namedPlatform)?.order_volume.toString();

        if (temp === platformOrders || (!temp?.length && platformOrders === '')) return true;
      }

      var hasChanged = (
        compareArrays(existingPlatforms, prospectData?.existing_platforms ? JSON.parse(prospectData?.existing_platforms).map(platform => platform.name) : []) &&
        compareArrays(currentEposProvider, JSON.parse(prospectData.existing_epos_providers) ?? []) &&
        (otherPlatforms === parsedPlatforms?.find(platform => !platformList.includes(platform.name))?.name || (!parsedPlatforms?.find(platform => !platformList.includes(platform.name))?.name?.length && otherPlatforms === '')) &&

        (shopName === prospectData.shop_name || (!prospectData.shop_name?.length && shopName === '')) &&
        (ownerName === prospectData.owner_name || (!prospectData.owner_name?.length && ownerName === '')) &&
        (mobileNumber === prospectData.mobile || (!prospectData.mobile?.length && mobileNumber === '')) &&
        (landlineNumber === prospectData.landline || (!prospectData.landline?.length && landlineNumber === '')) &&
        (ownerEmail === prospectData.email || (!prospectData.email?.length && ownerEmail === '')) &&
        (shopAddress === prospectData.address || (!prospectData.address?.length && shopAddress === '')) &&
        (location === prospectData.town || (!prospectData.town?.length && location === '')) &&
        (shopPostcode === prospectData.postcode || (!prospectData.postcode?.length && shopPostcode === '')) &&
        (shopScore === prospectData.matrix_value || (!prospectData.matrix_value?.length && shopScore === '')) &&
        (otherOrders === parsedPlatforms?.find(platform => !platformList.includes(platform.name))?.order_volume || (!parsedPlatforms?.find(platform => !platformList.includes(platform.name))?.order_volume?.length && otherOrders === '')) &&

        compareOrders('JustEat', justEatOrders) &&
        compareOrders('Deliveroo', deliverooOrders) &&
        compareOrders('UberEats', uberEatsOrders) &&
        compareOrders('FoodHub', foodHubOrders) &&
        compareOrders('OrderYoyo', orderYoyoOrders) &&
        compareOrders('FlipDish', flipDishOrders) &&
        otherProviders === (parsedProviders?.find(platform => !eposProviderData.includes(platform)) ?? '') &&

        (cashAndCarries === prospectData.preferred_cc || (!prospectData.preferred_cc?.length && cashAndCarries === ''))
      );

      return setSaveEnabled(!hasChanged);
    }
  }, [prospectData, existingPlatforms, currentEposProvider, shopName, ownerName, mobileNumber, landlineNumber, ownerEmail, shopAddress, location, shopPostcode, shopScore, justEatOrders, deliverooOrders, uberEatsOrders, foodHubOrders, orderYoyoOrders, flipDishOrders, otherPlatforms, otherOrders, otherProviders, cashAndCarries]);

  const compareArrays = (array1, array2) => {
    return array1?.length === array2?.length && array1?.every(v => array2?.includes(v));
  };

  return (
    <section className={`prospect-form ${prospectData ? 'prospect-form--prospect-overview' : ''}`}>
      <div className='form-section form-section__field-container form-section__field-container--shop-info'>
        {shopInfo.map(input => (
          <TextField
            key={input.label}
            placeholder={input.label}
            value={input.value}
            onChange={value => input.onChange(input.label === 'Postcode' ? value.toUpperCase() : value)}
            disabled={input.label === 'Score' && !hasManagerAccess(userID)}
            required={!prospectData && (input.label === 'Shop Name' || input.label === 'Postcode')}
            error={(input.label === 'Shop Name' && formErrors?.includes('shopname') || input.label === 'Postcode' && formErrors?.includes('postcode')) && !input.value} />
        ))}
      </div>

      <FormSection
        title='Existing Platforms'
        subtitle='Please select all that apply.'
        options={platformList}
        setOptions={setExistingPlatforms}
        selectedOptions={existingPlatforms}
        otherValue={otherPlatforms}
        setOtherValue={setOtherPlatforms} />

      <FormSection
        type="text"
        title='Gauge Order Volume'
        subtitle='Estimate weekly orders per platform.'
        options={platformData}
        selectedOptions={existingPlatforms}
        otherValue={otherOrders}
        setOtherValue={setOtherOrders}
        otherDisabled={!otherPlatforms} />

      <FormSection
        title='Current ePOS Provider'
        subtitle='Please select all that apply.'
        options={eposProviderData}
        setOptions={setCurrentEposProvider}
        selectedOptions={currentEposProvider}
        otherValue={otherProviders}
        setOtherValue={setOtherProviders} />

      <div className="form-section">
        <UserInputHeader title='Preferred Cash & Carries' description='Please list all go-to vendors below.' />
        <TextField
          placeholder='Cash & Carries'
          value={cashAndCarries}
          onChange={setCashAndCarries} />
      </div>
    </section>
  )
}

export default ProspectForm;
import {Admin} from '../../../fmo-api-sdk';
import {useEffect} from "react";
import {useState} from "react";
import Modal from "react-modal";
import ModalHeader from "../../modal-components/modal-header";
import ModalDropdown from "../../modal-components/modal-dropdown";
import ModalTextArea from "../../modal-components/modal-text-area";
import Button from "../../button";
import ModalList from "../../modal-components/modal-list";
import Tag from '../../tag';
import moment from 'moment';
import { useSelector } from 'react-redux';

type AddJobModalState = {
    shopId: string,
    shopName: string,
    jobId: string
}

const AddJobModal = ({data = {} as AddJobModalState, setClosed, jobHistory=false, isOpen=false}) => {
    const [reasons, setJobReasons] = useState([]);
    const [reason, setReason] = useState('');
    const [description, setDescription] = useState('');
    const [assignee, setAssignee] = useState<null | number>(null);
    const [priority, setPriority] = useState('');
    const [slackTracking, setSlackTracking] = useState<null | number>(null);
    const [validationErrors, setValidationErrors] = useState<Array<string>>([]);
    const staffList = useSelector((state: any) => state.staff);

    const isMobile = window.innerWidth < 768;

    const slackTrackingOptions = [
      {
        name: 'Yes',
        value: '1'
      },
      {
        name: 'No',
        value: '0'
      }
    ]

    const priorityOptions = [
      'Urgent',
      'High',
      'Medium',
      'Low'
    ];

    // Load data
    useEffect(() => {
        (async () => {
            const reasons = await Admin.getJobReasons();
            setJobReasons(reasons);
        })();
    }, []);

    const submitJob = async (): Promise<void> => {
      setValidationErrors([]);
      var tempErrors = [] as string[];

      if (!jobHistory) {
        if (!reason) {
          tempErrors.push('issue');
        }
        if (!assignee) {
          tempErrors.push('assignee');
        }
        if (!priority) {
          tempErrors.push('priority');
        }
        if (slackTracking === null) {
          tempErrors.push('slackTracking');
        }
        if (description === '') {
          tempErrors.push('description');
        }
      }

      if (tempErrors.length) {
        setValidationErrors(tempErrors);
      } else {
        await Admin.addJob(data.shopId, reason, description, assignee, slackTracking, priority === 'Medium' ? "Normal" : priority, data.jobId)
          .then(() => handleCloseModal(true))
          .catch(res => {
            alert('There was any error creating the job, please try again.')
            console.log('add job error: ', res)
          });
      }
    }

    const buttonDisabled = () => {
      if (jobHistory) {
          if (!assignee && !priority) return true;
      }
      if (validationErrors.length) {
        if (!reason || !assignee || !priority || !slackTracking || !description) return true;
      }
      return false
    }

    const handleCloseModal = (forceUpdate = false) => {
      setReason('');
      setAssignee(null);
      setPriority('');
      setSlackTracking(null);
      setDescription('');
      setValidationErrors([]);
      setClosed(forceUpdate);
    }

    const headerTags = () => (
      <>
        <Tag text={data.shopName} size="large" type="primary" />
        {!isMobile && <Tag text={moment().format('DD/MM/YY HH:mm')} size="large" type="date" />}
      </>
    )

    const modalContent = () => (
      <div className="modal__content modal-scrollbar">
        {!jobHistory && (
          <>
          <ModalDropdown
            title="Nature of Issue"
            description="What does the log relate to?"
            required 
            categories={reasons}
            selectedOption={reason}
            onClick={setReason}
            error={validationErrors.includes('issue')} />

        <ModalDropdown
          title={jobHistory ? "Re-Assign Job" : "Assign To"}
          description="Who should pick this up?"
          required
          categories={staffList}
          selectedOption={assignee}
          onClick={setAssignee}
          error={validationErrors.includes('assignee')} />

        <ModalList
          title={`${jobHistory ? 'Change' : 'Set'} Priority`}
          description="Affects how quickly we pick this up."
          required
          options={priorityOptions}
          selectedOption={priority}
          onClick={setPriority}
          error={validationErrors.includes('priority')} />

          <ModalList 
          title="Track Job Progress" 
          description="We’ll keep you posted via slack." 
          required
          options={slackTrackingOptions} 
          selectedOption={slackTracking} 
          onClick={setSlackTracking}
          error={validationErrors.includes('slackTracking')} />

          <ModalTextArea 
          value={description} 
          title="Notes" 
          onChange={ev => setDescription(ev.target.value)} 
          filled={Boolean(description)} 
          required 
          error={validationErrors.includes('description')} />
        </>
      )}

      {jobHistory && (
        <>
        <ModalDropdown
          title={jobHistory ? "Re-Assign Job" : "Assign To"}
          description="Who should pick this up?"
          required={false}
          categories={staffList}
          selectedOption={assignee}
          onClick={setAssignee}
          error={validationErrors.includes('assignee')} />

        <ModalList
          title={`${jobHistory ? 'Change' : 'Set'} Priority`}
          description="Affects how quickly we pick this up."
          required={false}
          options={priorityOptions}
          selectedOption={priority}
          onClick={setPriority}
          error={validationErrors.includes('priority')} />
        </>
      )}

        <div className="modal__submit-wrapper">
          <Button type="success" buttonText={jobHistory ? "Update" : "Submit"} onClick={() => submitJob()} grow disabled={buttonDisabled()} />
        </div>
      </div>
    )

    return (
      <>
        {jobHistory ? (
          modalContent()
        ) : (
          <Modal isOpen={isOpen} className="modal" overlayClassName="overlay">
            {!jobHistory && <ModalHeader title="Create Job" setClosed={handleCloseModal} tags={headerTags} />}
            {modalContent()}
          </Modal>
        )}
      </>
    )
}

export default AddJobModal;

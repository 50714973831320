import { useEffect, useState } from "react";
import {Admin} from '../../../../fmo-api-sdk';
import { useSelector} from "react-redux";
import { Chart } from "react-google-charts";
import moment from "moment";
import "./index.scss";
import { getUserId } from "../../../../app/auth";

const Marketing = ({filteredOrderData, setLoading, shopId}) => {
  const token = useSelector((state: any) => state.auth.token);
  const userId = getUserId(token)
  const [shopData, setShopData] = useState<any>([]);
  
  useEffect(() => {
    (async () => {
      const shopDataResponse = await Admin.getShopsManagedByAgent(userId)
        .catch(res => console.log(res))
      // console.log('allLogs', shopDataResponse.filter(shop => shop.marketing.logs.length))
      // console.log('allLogs', shopDataResponse.filter(shop => shop.marketing.logs.length).map(event => event.marketing.logs).flat().map(event => event.marketing_type))
      if (shopDataResponse) {
        let currentShop = shopDataResponse.find(shop => shop?.wholesaler_id.toString() === shopId);
        setShopData(currentShop);
      }

      setLoading(false)
    })();
  }, [])

  const lastMonthDayCount = moment().subtract(1, 'M').daysInMonth()

  const allowedLogTypes = ['sms', 'PPC GOOGLE', 'PPC FACEBOOK']
  const filteredLogData = shopData?.marketing?.logs.filter(event => allowedLogTypes.includes(event.marketing_type));

  let smsLastMonth = 0;
  let smsPrevMonth = 0;
  let ppcGoogle = 0;
  let ppcFacebook = 0;

  if (filteredLogData?.length) {
    for (const log of filteredLogData) {

      let month = '';
      if (moment().subtract(1, 'M').format('YYYY M') === moment(log.marketing_date).format('YYYY M')) month = 'lastMonth';
      else if (moment().subtract(2, 'M').format('YYYY M') === moment(log.marketing_date).format('YYYY M')) month = 'prevMonth';

      switch (log.marketing_type) {
        case "sms": {

          if (month === 'lastMonth') {
            smsLastMonth++;
          }
          if (month === 'prevMonth') {
            smsPrevMonth++;
          }
          break;
        }
        case "PPC GOOGLE": {
          if (month === 'lastMonth') {
            ppcGoogle++;
          }
          break;
        }
        case "PPC FACEBOOK": {
          if (month === 'lastMonth') {
            ppcFacebook++;
          }
          break;
        }
      }
    }
  }

  function getMarketingEvent(dayOfMonth) {
    if (filteredLogData?.length) {
      let formattedDayOfMonth = moment().subtract(1, 'months').format(`Y-MM-${dayOfMonth.toString().padStart(2, 0)}`);
      let event = filteredLogData.find(event => moment(event.marketing_date).format('Y-MM-DD') === formattedDayOfMonth)
      if (event) return event.marketing_type;
    }
    return null;
  }

  function generateChartData() {
    let i = 1
    let daysOrders;
    while (i-1 < lastMonthDayCount) {
      daysOrders = filteredOrderData.filter(order => i.toString() === moment(order.order_created).format('D')).length;
      chartData.push([i, daysOrders, getMarketingEvent(i)]);
      i++
    }
    chartData.unshift(["Age", "Weight", {role: "annotation"}])
  }
  
  let chartData: Array<any[]> = [];
  generateChartData();

  const marketingData: Array<any> = [
    {
      key: 'SMS',
      value: smsLastMonth,
      total: smsPrevMonth
    },
    {
      key: 'PPC Google',
      value: ppcGoogle,
    },
    {
      key: 'PPC Facebook',
      value: ppcFacebook,
    },
    {
      key: 'Emails',
      value: '??',
      total: '??'
    },
    {
      key: 'Push',
      value: '??',
      total: '??'
    },
  ]

  const isPrinting = window.location.pathname === '/breakdown' ? true : false;
  window.onafterprint = function(){
    window.close();
 }

  return (
    <div className="breakdown__section">
      <h2 className="breakdown__heading">Marketing</h2>
      <div className="marketing">
        <Chart
          chartType="LineChart"
          data={chartData}
          options= {{
            title: 'Orders & Marketing',
            hAxis: { title: "Day of Month", viewWindow: { min: 1, max: lastMonthDayCount }, format: '0' },
            vAxis: { title: "Orders", viewWindow: { min: 0 }, format: '0' },
            curveType: 'function',
            legend: "none",
          }}
          render={({ renderChart }) => {
            return (
              <div className="marketing__chart-wrapper">
                <div style={{ width: isPrinting ? '725px' : '100%' }}>{renderChart()}</div>
              </div>
            );
          }}
        />
        <div className="marketing__data">
          {marketingData.map((item) => {
            return (
              <div key={item.key} className="marketing__data-item">
                <div className="marketing__data-type">{item.key}</div>
                <div>{item.value}</div>
                {item.total && <div>({item.total})</div>}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Marketing;
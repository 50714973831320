import { useSelector } from 'react-redux';
import BurgerIcon from '../../../img/burgerAvatar.svg';
import './index.scss';

const UserTag = ({ribbon=false, user = null as any}) => {
  const userName = useSelector((state: any) => state.auth.userData);
  const parsedName = user ?? `${userName.first_name} ${userName.last_name[0]}.`

  var text1 = ribbon ? 'Agent:' : parsedName;

  return (
    <div className={`user-tag ${ribbon ? 'user-tag--ribbon' : ''}`}>
      <img src={BurgerIcon} alt="Assigned agent" />
      <span className='user-tag__bold'>{text1}</span>
      {ribbon && <span>{parsedName}</span>}
    </div>
  )
}

export default UserTag;
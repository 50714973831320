import "../index.scss";
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from "react";

interface Props {
  onClick?: () => void,
  title: string,
  type: string,
  description: string,
  headerTags: JSX.Element,
  infoTag: JSX.Element
  footerTags: JSX.Element,
  selectNote?: () => void
}

const TicketCardNew = ({onClick, title, type, description, headerTags, infoTag, footerTags, selectNote}: Props) => {
  const ref = useRef(null)
  const [willOpenModal, setWillOpenModal] = useState(false);

  useEffect(() => {
    // @ts-ignore
    setWillOpenModal(ref?.current?.scrollHeight > ref?.current?.clientHeight || type !== 'log')
  })

  const getOnClick = () => {
    if (type !== 'log' && onClick) return () => onClick();
    else if (willOpenModal && selectNote) return () => selectNote();
  }

  return (
    <div onClick={getOnClick()} className={`ticket-card ticket-card--${type} ${willOpenModal ? 'ticket-card--opens-modal' : ''}`}>
      <div className="ticket-card__header">
        <div>
          <div className="ticket-card__tag-wrapper">
            {headerTags}
          </div>

          <h3 className='ticket-card__title'>{title}</h3>
        </div>
      </div>

      <p ref={ref} className='ticket-card__description'>{description}</p>
      
      <div className='ticket-card__footer'>
        {infoTag}

        <div className="ticket-card__footer-right">
          {footerTags}
        </div>
      </div>
    </div>
  )
}

TicketCardNew.propTypes = {
  type: PropTypes.oneOf(['visit-log', 'log']),
}

export default TicketCardNew;
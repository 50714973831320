import { createSlice } from "@reduxjs/toolkit";

const initialState: any = null;

const setStaffReducer = (state, action) => state = action.payload;

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    setStaff: setStaffReducer
  }
});

export const { setStaff } = staffSlice.actions;
export const staffReducer = staffSlice.reducer;
import Alert from "../../img/alert.svg";
import OneStar from "../../img/starsOne.svg";
import TwoStar from "../../img/starsTwo.svg";
import ThreeStar from "../../img/starsThree.svg";
import "./index.scss";
import PropTypes from 'prop-types';
import { CSSProperties } from "react";

type Props = {
  size?: string,
  noShrink?: boolean,
  customStyles?: CSSProperties
} & (
    {
      type?: string,
      priority?: never,
    } & (
      {
        icon: string,
        altText: string,
        text?: string | number,
      } | {
        icon?: never,
        altText?: never,
        text: string | number,
      }
    ) | {
      type?: never,
      icon?: never,
      altText?: never,
      text?: never,
      priority: string,
    }
)

const Tag = ({icon, altText, text, type, size='medium', priority, noShrink=false, customStyles}: Props) => {
  var text2;

  if ((type === 'date' || type === 'date-grey') && text) {
    var splitDate = text.toString().split(" ");
    text = splitDate[0];
    text2 = splitDate[1];
  } else if (type === 'date-extended' && text){
    var splitDate = text.toString().split(" ");
    text = splitDate[0];
    splitDate.splice(0, 1);
    text2 = splitDate.join(" ");
  } else if (priority) {
    switch (priority) {
      case 'urgent':
        icon = Alert;
        altText = 'Urgent Priority';
        type = 'fmo';
        text = 'Urgent';
        break;
      case 'high':
        icon = ThreeStar;
        altText = 'High Priority';
        type = 'clementine';
        text = 'High';
        break;
      case 'low':
        icon = OneStar;
        altText = 'Low Priority';
        type = 'low-priority';
        text = 'Low';
        break;
      default:
        icon = TwoStar;
        altText = 'Medium Priority';
        type = 'medium-priority';
        text = 'Medium';
    }
  }

  return (
    <div className={`tag tag--${size} ${type ? `tag--${type}` : ''} ${noShrink ? 'tag--no-shrink' : ''}`} style={customStyles}>
      {icon && <img src={icon} alt={altText} />}
      <span className={`tag__text tag__text--${size}`}>{text}</span>
      {text2 && <span className={`tag__text tag__text--${size} tag__text--time`}>{text2}</span>}
    </div>
  )
}

Tag.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary', 'highlight', 'fmo', 'clementine', 'medium-priority', 'low-priority', 'date', 'date-extended', 'date-grey', 'postcode', 'completed', 'creme-brulee', 'required-default', 'required-focus', 'success', 'eel', 'bubblegum']),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  priority: PropTypes.oneOf(['urgent', 'high', 'medium', 'low', 'normal'])
}

export default Tag
import './index.scss';
import CountdownIcon from '../../../../img/icons/countdownIcon.svg'
import { useEffect, useState } from 'react';
import moment from 'moment';

const CheckInTimerTag = ({checkinData}) => {
  const [timer, setTimer] = useState('00:00:00');

  const getTimeSinceCheckin = () => {
    var timerHours = moment().diff(checkinData?.time, "hours");
    var timeAtCheckin = moment(checkinData?.time);
    var timerMinutesSeconds = moment.utc(moment().diff(timeAtCheckin)).format("mm:ss");

    setTimer(`${timerHours.toString().padStart(2, '0')}:${timerMinutesSeconds}`)
  }

  useEffect(() => {
    getTimeSinceCheckin();

    const interval = setInterval(() => {
      getTimeSinceCheckin();
    }, 1000);

    return () => clearInterval(interval);
  }, [checkinData]);


  return (
    <div className='timer-tag'>
      <img src={CountdownIcon} alt='Time since checkin' />
      <span className='timer-tag__timer'>{timer}</span>
    </div>
  )
}

export default CheckInTimerTag;
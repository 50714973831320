import './index.scss';
import { Admin } from '../../fmo-api-sdk';
import {orderBy, get, filter} from "lodash";
import { useEffect, useState } from 'react';
import moment from "moment";
import PuffLoader from "react-spinners/PuffLoader";
import { useHistory } from 'react-router-dom';
import { getReasonCode, interactionReasons } from './pipelineData';
import { getUserId } from '../../app/auth';
import { useSelector } from 'react-redux';
import Button from '../button';
import FilterIcon from '../../img/icons/filterIcon.svg';
import FilterIconLight from '../../img/icons/filterIconLight.svg';
import ProspectFilterModal from '../modals/prospect-filter';
import DeleteIconRed from '../../img/icons/deleteIconRed.svg';
import DeleteIconWhite from '../../img/icons/deleteIconWhite.svg';
import AppliedFilterTag from '../applied-filter-tag';
import UserIcon from '../../img/icons/userIconNew.svg';
import MapPinFilled from '../../img/mapPinFilled.svg';
import ZReportIcon from '../../img/icons/zReport.svg';
import LayoutListIcon from '../../img/icons/layoutList.svg';
import NoResults from '../../img/noResults.svg'
import SearchButton from '../header-components/search-button';
import SearchField from '../header-components/search-field';
import AddProspectIcon from '../../img/addedIconNewTruffle.svg';
import AddProspectFilled from '../../img/addedIconWhite.svg';
import Tag from '../tag';
import TimerIcon from '../../img/timer.svg';
import SuccessIcon from '../../img/greenTickFilled.svg';
import ErrorIcon from '../../img/icons/statusError.svg';
import { hasManagerAccess } from '../../assigneeData';
import FileIcon from '../../img/icons/fileSmall.svg'

const Pipeline = ({setLeftHeaderSlot, setRightHeaderSlot}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true)
  const [prospectData, setProspectData] = useState<any[]>([]);
  const [sorter, setSorter] = useState('shop_name');
  const [sortDir, setSortDir] = useState<string>("asc");
  const [prospectFilterModalOpen, setProspectFilterModalOpen] = useState(false);
  const [prospectFilterData, setProspectFilterData] = useState<any>({});
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);
  const [outcomeOptions, setOutcomeOptions] = useState<any>();

  const {agentFilters, postcodeFilters, stageFilters, outcomeFilters} = prospectFilterData;

  const isMobile = window.innerWidth < 768;

  const filtersEnabled = Object.values(prospectFilterData).find((prospect: any) => prospect?.length);
  const filtersOrSearchEnabled = filtersEnabled || !!searchFilter;

  const token = useSelector((state: any) => state.auth.token);
  const userAuthorised = hasManagerAccess(getUserId(token));

  const columnData = [
    isMobile ? {name: 'Outcome', value: 'status_name'} : {name: 'Source', value: 'source'},
    {name: 'Shop Name', value: 'shop_name'},
    {name: 'Postcode', value: 'postcode'},
    {name: 'Score', value: 'matrix_value'},
    {name: 'Interactions', value: 'interactions'},
    {name: 'Last Update', value: 'updated_at'},
    {name: 'Stage', value: 'stage'},
    {name: 'Agent', value: 'assignee_first_name'},
    isMobile ? {name: 'Source', value: 'source'} : {name: 'Outcome', value: 'status_name'},
  ];

  // useEffect(() => {
  //   if (prospectData.length && !loading) {
  //     window.scrollTo({top: parseInt(localStorage.getItem('pipelineScrollPos') as string)});
  //   };
  // }, [prospectData, loading]);

  useEffect(() => {
    (async () => {
      await fetchProspects()
        .then(data => {
          setProspectData(data);
          getOutcomeOptions(data);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
        });
    })();

    var savedSearch = localStorage.getItem('pipelineSearch') ?? '';
    var savedFilters = localStorage.getItem('pipelineFilters');
    setSearchFilter(savedSearch);
    setProspectFilterData(savedFilters
      ? JSON.parse(savedFilters)
      : {
        agentFilters: [],
        postcodeFilters: [],
        stageFilters: [],
        outcomeFilters: []
      }
    );


    // const onScroll = () => {
    //   if (window.scrollY) localStorage.setItem('pipelineScrollPos', window.scrollY < 150 ? '0' : window.scrollY.toString());
    // };

    // window.addEventListener("scroll", onScroll);

    // return () => {
    //   window.removeEventListener("scroll", onScroll);
    // };
  }, []);

  useEffect(() => {
    if (searchFilter) localStorage.setItem('pipelineSearch', searchFilter);
    else localStorage.setItem('pipelineSearch', '');
  }, [searchFilter]);

  useEffect(() => {
    if (filtersEnabled) localStorage.setItem('pipelineFilters', JSON.stringify(prospectFilterData));
    else localStorage.setItem('pipelineFilters', '');
  }, [prospectFilterData]);

  const prospectCounter = () => {
    let counterData;

    if (!filtersOrSearchEnabled || isMobile) {
      counterData = filteredData?.length; // ?? 0
    } else counterData = `${filteredData?.length} / ${prospectData?.length}`

    return (
      <div className='pipeline__counter'>{counterData}</div>
    )
  };

  const newProspectButton = () => (
    <Button
      icon={prospectData.length ? AddProspectIcon : AddProspectFilled}
      iconAltText='Add prospect button'
      onClick={() => history.push('/new-prospect')}
      type={prospectData.length ? 'tertiary' : 'primary'}
      square
      condensed />
  )

  const headerButtonsRight =
    <>
      {filtersEnabled &&
        <Button
          onClick={() => clearFilters()}
          icon={isMobile ? '' : DeleteIconRed}
          hoverIcon={isMobile ? '' : DeleteIconWhite}
          iconAltText={isMobile ? '' : 'Clear filters'}
          condensed
          type="danger"
          buttonText={isMobile ? 'Clear' : 'Clear All'} />
      }

      <Button
        buttonText={isMobile ? '' : 'Apply Filters'}
        onClick={() => setProspectFilterModalOpen(true)}
        type='tertiary'
        condensed
        icon={!prospectData.length ? FilterIconLight : FilterIcon}
        iconAltText={'Open prospect filter'}
        disabled={!prospectData.length}
        square={isMobile} />

      {isMobile ? (
          <>
            <SearchButton onClick={() => setSearchOpen(!searchOpen)} />
            {newProspectButton()}
          </>
        ) : (
          <SearchField
            onSearch={setSearchFilter}
            placeholder="Search name or postcode"
            disabled={!prospectData?.length} />
      )}

    </>

  useEffect(() => {
    setLeftHeaderSlot(<>
      {prospectCounter()}
      {!isMobile && newProspectButton()}
    </>);
    setRightHeaderSlot(headerButtonsRight);
  }, [prospectData, prospectFilterData, filteredData, searchOpen]);

  let fetchProspects;
  if (userAuthorised) {
    fetchProspects = Admin.getAllProspects;
  } else fetchProspects = Admin.getMyProspects;

  useEffect(() => {
    if (!!searchFilter) setSearchOpen(false);
  }, [searchFilter]);

  // Table sorting
  const sortTable = head => {
      let newSortDir = 'asc';
      if (sorter === head) {
          newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
      }

      setSortDir(newSortDir);
      setSorter(head);
  };

  const getFilteredInteractions = interactions => {
    return interactions.filter(interaction => moment(interaction.updated_at).diff(moment(interaction.created_at)) > 2000);
  }

  // Sort the dataset
  const sorted = orderBy(filteredData, [prospect => {
      let value;

      if (sorter === 'updated_at') {
        value = getFilteredInteractions(prospect.interactions)[0]
          ? moment(getFilteredInteractions(prospect.interactions)[0]?.updated_at).format()
          : ''
      } else if (sorter === 'stage') {
        if (['1', '5'].includes(prospect.status_id)) {
          var filteredIntertactions = getFilteredInteractions(prospect.interactions);

          if (filteredIntertactions.length) value = filteredIntertactions[0]?.reason_id
          else value = 0
        } else value = 10;
      } else if (sorter === 'interactions') {
        value = getFilteredInteractions(get(prospect, sorter, 0)).length;
      } else if (sorter === 'status_name') {
        value = get(prospect, sorter, 0);
        if (value === 'PendingWIn') value = 'To Review';
        if (value === 'WinRejected') value = 'Rejected';
      } else value = get(prospect, sorter, 0);

      // If the values are strings, make the sort case-insensitive
      if (typeof value === 'string') {
          return value.toLowerCase();
      }

      return value;
  }], [sortDir]);

  const handleShopFilterModalClose = filterData => {
    if (filterData) {
      setProspectFilterData(filterData)
    }
    setProspectFilterModalOpen(false)
  }

  useEffect(() => {
    var tempFiltered = prospectData;

    if (searchFilter) tempFiltered = filter(tempFiltered, prospect => prospect.shop_name.toLowerCase().includes(searchFilter.toLowerCase()) || prospect.postcode.toLowerCase().includes(searchFilter.toLowerCase()));
    if (agentFilters?.length) tempFiltered = filter(tempFiltered, prospect => agentFilters.includes(`${prospect.assignee_first_name} ${prospect.assignee_last_name}`));
    if (postcodeFilters?.length) tempFiltered = filter(tempFiltered, prospect => postcodeFilters.includes(trimPostcode(prospect.postcode)));
    if (stageFilters?.length) {
      tempFiltered = filter(
        tempFiltered, prospect => (stageFilters.includes(6) && ['2', '3', '6', '7', '8'].includes(prospect.status_id)) ||
        (['1', '5'].includes(prospect.status_id) && (
          stageFilters.includes(parseInt(getFilteredInteractions(prospect.interactions)[0]?.reason_id)) ||
          (stageFilters.includes(0) && !getFilteredInteractions(prospect.interactions).length)
        ))
    )};
    if (outcomeFilters?.length) {
      var tempOutcomeFilters = [...outcomeFilters];
      if (tempOutcomeFilters.includes('5')) tempOutcomeFilters.push('7');
      if (tempOutcomeFilters.includes('6')) tempOutcomeFilters.push('8');

      tempFiltered = filter(tempFiltered, prospect => tempOutcomeFilters.includes(prospect.status_id));
    }

    setFilteredData(tempFiltered);
  }, [prospectData, prospectFilterData, searchFilter]);

  const removeFilter = (filterType, removedFilter) => {
    var tempFilterData = {...prospectFilterData};
    tempFilterData[filterType] = tempFilterData[filterType].filter(filter => filter !== removedFilter);

    setProspectFilterData(tempFilterData);
  }

  const clearFilters = () => {
    setProspectFilterData({
      agentFilters: [],
      postcodeFilters: [],
      stageFilters: [],
      outcomeFilters: []
    });
    setSearchFilter('');
  };

  const trimPostcode = fullPostcode => {
    var tempPostcode = fullPostcode.trim()
    var regex = userAuthorised ? /\d/ : ' ';
    return tempPostcode.trim().substring(0, tempPostcode.indexOf(tempPostcode.match(regex)));
  };

  const getAssigneeOptions = () => {
    var assigneeArray: any = prospectData.map(prospect => `${prospect.assignee_first_name} ${prospect.assignee_last_name}`).sort();
    assigneeArray = [...new Set(assigneeArray)];
    assigneeArray = assigneeArray.map(assignee =>
      ({
        'name': assignee,
        'value': assignee,
        'subValue': filter(prospectData, prospect => assignee === `${prospect.assignee_first_name} ${prospect.assignee_last_name}`).length
      }))

    return assigneeArray;
  };

  const getPostcodeOptions = () => {
    var trimmedPostcodes = prospectData.map(prospect => trimPostcode(prospect.postcode)).sort();
    var validatedPostcodes = [...new Set(trimmedPostcodes)];
    // below sorts alphanumeric postcodes
    var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
    validatedPostcodes.sort(collator.compare);

    var postcodeArray = validatedPostcodes.map(postcode =>
      ({
        'name': postcode,
        'value': postcode,
        'subValue': filter(prospectData, prospect => postcode === ""
          ? prospect.postcode === ''
          : trimPostcode(prospect.postcode) === (postcode)).length,
      })
    );

    return postcodeArray;
  };

  const getStageOptions = () => {
    const getStageFilter = (stage, prospect) => {
      if (stage.value === 6) return ['2', '3', '6', '7', '8'].includes(prospect.status_id);
      else if (stage.value === 0) return ['1', '5'].includes(prospect.status_id) && !getFilteredInteractions(prospect.interactions).length;
      else return ['1', '5'].includes(prospect.status_id) && parseInt(getFilteredInteractions(prospect.interactions)[0]?.reason_id) === stage.value;
    };

    var optionsArray = [{name: 'New Prospect', value: 0}, ...interactionReasons, {name: 'Closed', value: 6}];
    var stageArray = optionsArray.map(stage => {
      var stageCount = filter(prospectData, prospect => getStageFilter(stage, prospect)).length;

      return ({
        name: stage.name,
        value: stage.value,
        subValue: stageCount,
        disabled: !stageCount,
      })
    });

    return stageArray;
  };

  const getOutcomeOptions = data => {
    var pending = filter(data, prospect => prospect.status_id === '1');
    var won = filter(data, prospect => prospect.status_id === '2');
    var lost = filter(data, prospect => prospect.status_id === '3');
    var review = filter(data, prospect => ['5', '7'].includes(prospect.status_id));
    var rejected = filter(data, prospect => ['6', '8'].includes(prospect.status_id));

    setOutcomeOptions(
      [{
        name: 'Pending',
        value: '1',
        subValue: pending.length,
        disabled: !pending.length
      },
      {
        name: 'Won',
        value: '2',
        subValue: won.length,
        disabled: !won.length
      },
      {
        name: 'Lost',
        value: '3',
        subValue: lost.length,
        disabled: !lost.length
      },
      {
        name: 'To Review',
        value: '5',
        subValue: review.length,
        disabled: !review.length
      },
      {
        name: 'Rejected',
        value: '6',
        subValue: rejected.length,
        disabled: !rejected.length
      }]
    )
  };

  // is below necessary?
  const getCellData = (columnType, value) => {
    if (columnType === 'status_name') {
      if (value === 'Open') return <Tag text='Pending' icon={TimerIcon} altText='Prospect pending' type='highlight' size='large' />;
      if (value === 'Won') return <Tag text={value} icon={SuccessIcon} altText='Prospect won' type='success' size='large' />;
      if (['To Review', 'PendingWIn'].includes(value)) return <Tag text={'To Review'} icon={FileIcon} altText='Prospect in review' type='required-default' size='large' />;
      if (['Rejected', 'WinRejected'].includes(value)) return <Tag text={'Rejected'} type='bubblegum' size='large' />;
      return <Tag text={value} type='fmo' icon={ErrorIcon} altText='Prospect lost' size='large' />
    } else if (columnType === 'source') {
      return `${value.toLowerCase().charAt(0).toUpperCase() + value.toLowerCase().slice(1)}`;
    };
    return value;
  }

  const getTableCell = (columnType, value, lead) => {
    var cellData = getCellData(columnType, value);
    var cellClass = 'pipeline-table__cell';

    if (columnType === 'status_name') cellClass += ' pipeline-table__cell--outcome';
    if ([0, 'N/a'].includes(cellData)) cellClass += ' pipeline-table__cell--disabled';

    return (
      <td key={columnType}>
        <div onClick={() => openProspectOverview(lead)} className={cellClass}>
          {cellData}
        </div>
      </td>
    )
  }

  const openProspectOverview = prospect => {
    history.push(`/prospect-overview/${prospect.id}`, prospect);
  }

  const getPipelineTop = () => {
    if (isMobile) {
      if (filtersOrSearchEnabled && searchOpen) return 192;
      if (filtersOrSearchEnabled) return 144;
      if (searchOpen) return 152;
    } else if (filtersOrSearchEnabled) return 168;
    return 104;
  }

  return (
    <>
      <ProspectFilterModal
        isOpen={prospectFilterModalOpen}
        setClosed={handleShopFilterModalClose}
        filterData={prospectFilterData}
        setProspectFilterData={setProspectFilterData}
        clearFilters={clearFilters}
        agentOptions={userAuthorised ? getAssigneeOptions() : null}
        postcodeOptions={getPostcodeOptions()}
        stageOptions={getStageOptions()}
        outcomeOptions={outcomeOptions} />

      {searchOpen &&
        <div className='pipeline__search-wrapper'>
          <SearchField
            onSearch={setSearchFilter}
            placeholder="Search name or postcode"
            closeField={() => setSearchOpen(false)}
            disabled={!prospectData?.length} />
        </div>
      }

      <div className='pipeline' style={{top: getPipelineTop()}}>
        {filtersOrSearchEnabled &&
          <div className='pipeline__filter-button-wrapper' style={{top: searchOpen && isMobile ? 128 : 80}}>
          {agentFilters?.map(agent => (
              <AppliedFilterTag
                text={agent}
                removeFilter={() => removeFilter('agentFilters', agent)}
                icon={UserIcon}
                iconAlt='Agent filter applied'
                size={isMobile ? 'small' : 'medium'} />
          ))}

          {postcodeFilters?.map(postcode => (
              <AppliedFilterTag
                text={postcode}
                removeFilter={() => removeFilter('postcodeFilters', postcode)}
                icon={MapPinFilled}
                iconAlt='Postcode filter applied'
                size={isMobile ? 'small' : 'medium'} />
          ))}

            {stageFilters?.map(stage => (
              <AppliedFilterTag
                text={stage === 6 ? 'Closed' : getReasonCode(stage)}
                removeFilter={() => removeFilter('stageFilters', stage)}
                icon={LayoutListIcon}
                iconAlt='Stage filter applied'
                size={isMobile ? 'small' : 'medium'} />
            ))}

            {outcomeFilters?.map(outcome => (
              <AppliedFilterTag
                text={outcomeOptions?.find(option => option.value === outcome)?.name ?? ''}
                removeFilter={() => removeFilter('outcomeFilters', outcome)}
                icon={ZReportIcon}
                iconAlt='Outcome filter applied'
                size={isMobile ? 'small' : 'medium'} />
            ))}

            {searchFilter &&
              <AppliedFilterTag
                text={searchFilter}
                removeFilter={() => setSearchFilter('')}
                size={isMobile ? 'small' : 'medium'} />
            }
          </div>
        }

        <h1 className='pipeline__title'>Pipeline</h1>

        {loading ? (
          <div className="spinner-border">
              <PuffLoader
                  size={75}
                  color={"#D82B31"}
                  loading={true}
              />
          </div>
        ) : (
          <div className='pipeline-table__wrapper'>
            {filteredData?.length ? (
              <table className='pipeline-table'>
                <thead className='pipeline-table__head'>
                  <tr>
                    {columnData.map(header => {
                      if (header.value === 'postcode') {
                        return null;
                      }
                      if (header.value === 'shop_name') {
                        return (
                          <th key={header.value} className='pipeline-table__cell-wrapper-combined'>
                            <div className='pipeline-table__cell pipeline-table__cell--header pipeline-table__cell--combined'>
                              <div onClick={() => sortTable('shop_name')}>Shop Name</div>
                              <div onClick={() => sortTable('postcode')}>Postcode</div>
                            </div>
                          </th>
                        )
                      } else {
                        return (
                          <th key={header.value} className={`pipeline-table__cell-wrapper-${header.value}`}>
                            <div onClick={() => sortTable(header.value)} className='pipeline-table__cell pipeline-table__cell--header'>{header.name}</div>
                          </th>
                        )
                      }
                    })}
                  </tr>
                </thead>

                <tbody>
                  {sorted.map((lead, index) => (
                    <tr key={index} className={`pipeline-table__row ${(['6', '8'].includes(lead.status_id)) ? 'pipeline-table__row--rejected' : ''}`}  onClick={() => openProspectOverview(lead)}>
                      {columnData.map(column => {
                        if (column.value === 'postcode') {
                          return null
                        } else if (column.value === 'shop_name') {
                          return (
                            <td key={column.value}>
                              <div className='pipeline-table__cell pipeline-table__cell--combined'>
                                <span className='pipeline-table__shop-name'>{lead.shop_name}</span>
                                <span>{lead.postcode}</span>
                              </div>
                            </td>
                          )
                        } else {
                          let value;

                          if (column.value !== 'stage' && column.value !== 'updated_at' && column.value !== 'interactions') {
                            value = lead[column.value];
                          } else {
                            var filteredIntertactions = getFilteredInteractions(lead?.interactions);

                            if (column.value === 'stage') {
                              if (['2', '3', '6', '7', '8'].includes(lead.status_id)) value = 'Closed';
                              else value = getReasonCode(filteredIntertactions[0]?.reason_id);
                            } else if (column.value === 'updated_at') {
                              value = filteredIntertactions[0]?.updated_at ? moment(filteredIntertactions[0].updated_at).format('DD/MM/YY') : 'N/a';
                            } else {
                              value = filteredIntertactions.length;
                            }
                          }

                          return getTableCell(column.value, value, lead);
                        }
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className={`pipeline-empty ${filtersOrSearchEnabled ? '' : 'pipeline-empty--no-results'}`}>
                <img src={NoResults} alt="No results found" className="pipeline-empty__icon" />
                <span className="pipeline-empty__line-1">
                  {prospectData.length
                    ? 'Uh oh! We couldn’t find any matches.'
                    : 'You have no assigned prospects!'
                  }
                </span>
                <span className="pipeline-empty__line-2">
                  {prospectData.length
                    ? 'Looks like there’s no results that fit your criteria.'
                    : 'Please come back later.'
                  }
                </span>
              </div>
            )}
          </div>
        )}

      </div>
    </>
  )
}

export default Pipeline;
import { useEffect,  useState } from "react";
import jobsToDo from "../../img/jobsToDo.svg";
import jobsInReview from "../../img/jobsInReview.svg";
import jobsDone from "../../img/jobsDone.svg";
import { filter, sortBy } from "lodash";
import "./index.scss";
import {Admin} from '../../fmo-api-sdk';
import JobHistoryModal from "../modals/job-history"
import ButtonFilter from "../button-filter";
import TicketCard from "../ticket-card";

const AgentJobs = () => {

    const [jobs, setJobs] = useState<any[]>([]);
    const [completed, setCompleted] = useState<any[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentJob, setCurrentJob] = useState<any>(null);
    const [tab, setTab] = useState<number>(0);
    const [sort, setSort] = useState<number>(parseInt(localStorage.getItem('myJobsSorter') || '0'));
    const [autoGenJob, setAutoGenJob] = useState(false);
    const [savedScrollPos, setSavedScrollPos] = useState(0);

    useEffect(() => {
        fetchAgentJobs();

        const onScroll = () => {
          setSavedScrollPos(window.scrollY)
        };

        window.addEventListener("scroll", onScroll);

        return () => {
          window.removeEventListener("scroll", onScroll);
        };
    }, []);

    useEffect(() => {
      if (savedScrollPos) localStorage.setItem('myJobsScrollPos', window.scrollY < 150 ? '0' : JSON.stringify(window.scrollY));
    }, [savedScrollPos])

    useEffect(() => {
      window.scrollTo({top: parseInt(localStorage.getItem('myJobsScrollPos') || '0')});
    }, [jobs])

    //prevent scrolling when modal is open    
    const windowWidth = window.innerWidth;
    const preventScrolling = function (showModal) {
        if (showModal) {
            document.body.classList.add("no-scroll");
        }
        else {
            document.body.classList.remove("no-scroll");
        }
    }

    preventScrolling(showModal);

    const priorityOrder = {
      'Urgent': 4,
      'High': 3,
      'Normal': 2,
      'Low': 1,
    }

    const fetchAgentJobs = async(forceUpdate = false) => {
        console.log('sort fetch', sort);
        setCurrentJob(null);
        const data = await Admin.getAgentJobs({forceUpdate})
          .catch(res => console.log(res));

        const tempJobs = filter(data, j => !j.type.includes("complete") && j.status !== "Completed");
        const tempCompleted = filter(data, j => j.type.includes("complete") || j.status === "Completed"); 
        
        const sortedJobs = sortBy(tempJobs, j => {
            if (sort === 0) return j.postcode;
            if (sort === 1) return j.created;
            if (sort === 2) return priorityOrder[j.priority];
        });

        const sortedComp = sortBy(tempCompleted, j => {
            if (sort === 0) return j.postcode;
            if (sort === 1) return j.created;
            if (sort === 2) return priorityOrder[j.priority];
        })
        
        // setJobs(data);
        if (sort === 0) {
            setJobs(sortedJobs);
            setCompleted(sortedComp);
        }

        if (sort === 1) {
            setJobs(sortedJobs.reverse());
            setCompleted(sortedComp.reverse());
        }

        if (sort === 2) {
            setJobs(sortedJobs.reverse());
            setCompleted(sortedComp.reverse());
        }
    }

    useEffect(() => {
        localStorage.setItem('myJobsSorter', sort.toString());
        console.log('sort useeffect', sort)

        const sortedJobs = sortBy(jobs, j => {
            if (sort === 0) return j.postcode;
            if (sort === 1) return j.created;
            if (sort === 2) return priorityOrder[j.priority];
        });

        const sortedComp = sortBy(completed, j => {
            if (sort === 0) return j.postcode;
            if (sort === 1) return j.created;
            if (sort === 2) return priorityOrder[j.priority];
        })

        if (sort === 0) {
            setJobs(sortedJobs);
            setCompleted(sortedComp);
        }

        if (sort === 1) {
            setJobs(sortedJobs.reverse());
            setCompleted(sortedComp.reverse());
        }

        if (sort === 2) {
            setJobs(sortedJobs.reverse());
            setCompleted(sortedComp.reverse());
        }
    }, [sort])

    const openModal = (job, autoGenerated) => {
      return () => {
        setCurrentJob(job);
        setShowModal(true);
        setAutoGenJob(autoGenerated);
      }
    }

    return (
        <div id="agent-jobs">
            <JobHistoryModal isOpen={showModal} data={{currentJob}} autoGenerated={autoGenJob} setClosed={jobUpdated => {
                setShowModal(false);
                fetchAgentJobs(jobUpdated);
            }}/>
            <div className="header-area" style={{padding: 0}}>
                <div className={"header-box " + (tab === 0 ? 'toDoActive' : '')} onClick={() => setTab(0)}>
                        <div className="box">
                            <div>ToDo</div>
                            <div>{jobs.length}</div>
                        </div>
                        <img src={jobsToDo} alt="jobimage" className={"hideImg" + (tab === 0 ? 'displayImg' : '')}/>
                </div>
                <div className={"header-box " + (tab === 1 ? 'inReviewActive' : '')} onClick={() => setTab(1)}>
                        <div className="box">
                            <div>In Review</div>
                            <div>0</div>
                        </div>
                        <img src={jobsInReview} alt="jobimage" className={"hideImg" + (tab === 1 ? 'displayImg' : '')}/>
                </div>
                <div className={"header-box " + (tab === 2 ? 'doneActive' : '')} onClick={() => setTab(2)}>
                        <div className="box">
                            <div className={tab === 1 ? 'dark-text' : ''}>Done</div>
                            <div className={tab === 2 ? 'green-text' : ''}>{completed.length}</div>
                        </div>
                        <img src={jobsDone} alt="jobimage" className={"hideImg" + (tab === 2 ? 'displayImg' : '')}/>
                </div>
            </div>

            <div className="jobs-area">
                <div className="header-row">
                    <div className="title">My Jobs</div>
                    <div className="show-only">
                        <div className="label">Sort by</div>
                        <ButtonFilter buttonText={'Date'} active={sort === 1} onClick={() => setSort(1)} />
                        <ButtonFilter buttonText={'Postcode'} active={sort === 0} onClick={() => setSort(0)} />
                        <ButtonFilter buttonText={'Priority'} active={sort === 2} onClick={() => setSort(2)} />
                    </div>
                </div>
                <div className="job-cards">
                    {tab === 0 ?
                        jobs.map((job, idx) =>
                            <TicketCard key={idx} job={job} onClick={openModal} />
                        )

                    : tab === 1 ?
                        completed.map((job,idx) =>
                            <TicketCard key={idx} job={job} onClick={openModal} />
                        )
                    :
                        completed.map((job,idx) =>
                          <TicketCard key={idx} job={job} onClick={openModal} completed />
                        )}
                </div>
            </div>
        </div>
    );
}

export default AgentJobs;

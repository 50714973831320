import './index.scss';
import CloseIcon from '../../../../img/x.svg'

const ErrorBanner = ({type, heading, subheading, onClear}) => (
  <div className={`error-banner error-banner--${type}`}>
    <div className="error-banner__content">
      <span className="error-banner__heading">{heading}</span>
      <span className="error-banner__subheading">{subheading}</span>
    </div>

    <img src={CloseIcon} onClick={onClear} className="error-banner__icon" />
  </div>
)

export default ErrorBanner;
import { useRef, useState } from 'react';
import './index.scss';
import Tag from '../tag';

type Props = {
  placeholder: string,
  value: any,
  onChange: (any) => void,
  disabled?: boolean,
  required?: boolean,
  error?: boolean
} & (
  {
    icon: string,
    iconAlt: string,
  } | {
    icon?: never,
    iconAlt?: never,
  }
)

const TextField = ({placeholder, value, onChange, icon, iconAlt, disabled=false, required=false, error=false}: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isFocussed, setIsFocussed] = useState(false);

  return (
    <div onClick={() => ref.current?.focus()} className={`text-field ${isFocussed ? 'text-field--focussed' : ''} ${disabled ? 'text-field--disabled' : ''} ${error ? 'text-field--error' : ''}`}>
      <div className="text-field__inner">
        <label
          htmlFor={placeholder}
          className={`text-field__label ${!!value && 'text-field__label--filled'} ${disabled ? 'text-field__label--disabled' : ''}`}>
          {placeholder}
        </label>

        <input
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e.target.value)}
          className={`text-field__input ${value ? 'text-field__input--filled' : ''}`}
          onFocus={() => setIsFocussed(true)}
          onBlur={() => setIsFocussed(false)}
          disabled={disabled}
          ref={ref} />
      </div>

      <div className='text-field__icon-wrapper'>
        {required && !isFocussed && !value && <Tag text='Required' type={error ? 'required-focus' : 'required-default'} size='large' />}
        {icon && <img src={icon} alt={iconAlt} className="text-field__icon" />}
      </div>
    </div>
  )
}

export default TextField;
export const getName = (staffList, id, type) => {
  const fullName = Object.values(staffList).flat().find(staffMember => staffMember.value === id)?.name;

  if (type === 'fullName') return fullName;
  if (type === 'initial') return fullName?.substring(0, (fullName.indexOf(' ') + 2));
  return fullName?.substring(0, fullName.indexOf(' '));
}

export const hasManagerAccess = userId => {
  const privilegedUsers = ['260', '2', '107', '10246']; // Parvase, Matt, Sean, Ryan S

  if (privilegedUsers.includes(userId)) return true;
  return false;
}